export const globalConfig = {
    auth0: {
        clientId: "",
        domain: "",
        redirectUri: "",
        audience: "",
    },
    // newartist: false,
    // country: "",
    stripe: {
        publicKey: "",
    },
    google: {
        apiKey: "",
        analyticsId: "",
    },
    // appInsights: {
    //     key: "",
    // }
}