

export class CurrentUserState {

    // yes this class is a bit shit and all over the plce

    constructor() {
    }

    private getReplaceNulls = (storeName: string, replaceIfUndefined: string) => {
        const stored = localStorage.getItem(storeName);
        if (stored == "undefined") return replaceIfUndefined;
        return stored || replaceIfUndefined;
    }

    get emailVerified(): string { return localStorage.getItem('email_verified') }
    set emailVerified(val: string) { localStorage.setItem('email_verified', val) }

    get nickname(): string { return localStorage.getItem('user_nickname') }
    set nickname(val: string) { localStorage.setItem('user_nickname', val) }

    get name(): string { return this.getReplaceNulls('user_name', this.nickname) }
    set name(val: string) { localStorage.setItem('user_name', val) }

    get familyName(): string { return localStorage.getItem('family_name') || "" }
    set familyName(val: string) { localStorage.setItem('family_name', val) }

    get givenName(): string { return this.getReplaceNulls('given_name', this.nickname) }
    set givenName(val: string) { localStorage.setItem('given_name', val) }

    get email(): string { return localStorage.getItem('user_email') }
    set email(val: string) { localStorage.setItem('user_email', val) }

    get picture(): string { return localStorage.getItem('user_picture') }
    set picture(val: string) { localStorage.setItem('user_picture', val) }

    get userId(): string { return localStorage.getItem('user_id') }
    set userId(val: string) { localStorage.setItem('user_id', val) }

    get accessToken(): string { return localStorage.getItem('access_token') }
    set accessToken(val: string) { localStorage.setItem('access_token', val) }

    get idToken(): string { return localStorage.getItem('id_token') }
    set idToken(val: string) { localStorage.setItem('id_token', val) }

    get loginExpires(): string { return localStorage.getItem('expires_at') }
    set loginExpires(val: string) { localStorage.setItem('expires_at', val) }

    get nonce(): string { return localStorage.getItem('nonce') }
    set nonce(val: string) { localStorage.setItem('nonce', val) }

    get signupDate(): string { return localStorage.getItem('signupDate') }
    set signupDate(val: string) { localStorage.setItem('signupDate', val || "") }

    get displayName(): string {
        return (this.name == this.email)
            ? this.nickname
            : this.name;
    }

    get displayShortName(): string {
        return (this.name == this.email)
            ? this.nickname
            : this.givenName;
    }

    // not entirely sure if this is right
    get isSocialConnection(): boolean {
        // as far as i can tell, "auth0" at the start of the user id means
        // the user is in out database rather than a social account
        // social accounts are like "google-oauth2"
        return this.userId && this.userId.split("|").shift() != "auth0";
    }

    isAuthenticated() {
        if (!this.loginExpires) return false;
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(this.loginExpires);
        return new Date().getTime() < expiresAt;
    }

    loginExpired() {
        if (!this.loginExpires) return false; // no login deets, so it's not expired, they just don't have a login
        let expiresAt = JSON.parse(this.loginExpires);
        return new Date().getTime() >= expiresAt;
        // return new Date().getTime() >= expiresAt - (1000 * 60 * 60 * 2) + (1000 * 5);
    }


    clear() {

        localStorage.removeItem('email_verified');
        localStorage.removeItem('family_name');
        localStorage.removeItem('given_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_nickname');
        localStorage.removeItem('user_picture');
        localStorage.removeItem('user_id');
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('nonce');

        // this.emailVerified = null;
        // this.name = null;
        // this.familyName = null;
        // this.givenName = null;
        // this.nickname = null;
        // this.email = null;
        // this.picture = null;
        // this.userId = null;
        // this.accessToken = null;
        // this.idToken = null;
        // this.loginExpires = null;
        // this.nonce = null;
    }

}
