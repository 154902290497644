import * as FullStory from '@fullstory/browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useLayoutEffect } from 'react';
import * as ReactGA from 'react-ga';
import { ArtistModel, ClientConfig, TourModel, TrackingEventModel } from '../../api/services';
import { TTApi } from '../../api/ttApi';
import { CurrentUserState } from '../../auth/currentUserState';

// cheap and nasty
const isGuid = (s: string) => s.split("-").length == 5;

// takes a path and changes the guids so that the path isn't specific to the user   
const getGenericTrackingPath = (path: string) => {
    if (!path) return "";

    // some abberations
    if (path.startsWith("/accept/")) return "/accept/:token";

    const bits = path.split("/");

    // first one should be the slash
    // second one should be the artist id
    if (bits.length > 1) {
        if (isGuid(bits[1])) bits[1] = ":artistId"
    }
    // is the 3rd the tourid?
    if (bits.length > 3) {
        if (bits[2] == "tours") {
            if (isGuid(bits[3])) bits[3] = ":tourId"
        }
    }
    // is the 5th the budget?
    if (bits.length > 5) {
        if (bits[4] == "budget" || bits[4] == "itinerary") {
            if (isGuid(bits[5])) bits[5] = ":budget"
        }
    }

    return bits.join("/");
}

// hmmmm
let appInsights: ApplicationInsights = undefined;

export const TTTracking = (config: ClientConfig) => {

    const startAppInsights = () => {

        const key = config.appInsightsKey;
        if (!key) return;
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key
                /* ...Other Configuration Options... */
            }
        });
        appInsights.loadAppInsights();
    }

    const startFullStory = () => {

        const id = config.fullStoryOrgId;

        if (!!id) {
            FullStory.init({ orgId: id });
        }
    }


    return {

        start: () => {
            // initialise google analytics
            if (!!config.googleAnalyticsId) ReactGA.initialize(config.googleAnalyticsId);
            // ReactGA.pageview(window.location.pathname + window.location.search);

            startAppInsights();
            startFullStory()
        },

        trackCurrentPath: () => {
            const trackPath = getGenericTrackingPath(document.location.pathname);

            // app insights
            if (!!appInsights) {

                (appInsights as any).trackPageView(trackPath);
            }

            // google analytics
            if (!!config.googleAnalyticsId) ReactGA.pageview(trackPath);

        },

        trackUser: () => {
            const user = new CurrentUserState();
            if (!user.isAuthenticated()) return;

            // google analytics
            // NOTE: GA doesn't allow tracking of email addresses or other data to make the user identifiable
            // see here https://analytics.google.com/analytics/web/?authuser=0#/a123718630w182058548p179825219/admin/tracking/user-id/
            if (!!config.googleAnalyticsId) ReactGA.set({ userId: user.userId });


            // full story
            if (!!FullStory && !!config.fullStoryOrgId) {
                debugger;
                console.log(FullStory)
                FullStory.identify(user.userId, {
                    displayName: user.displayName,
                    name: user.name,
                    email: user.email,
                    pictureUrl: user.picture,
                    rootUrl: window.location.host,

                    // http://help.fullstory.com/develop-js/setuservars
                });
            }
        }

    }

}


// **** tourtracks custom tracking events ********

const trackEvent = async (data: TrackingEventModel) => {
    await TTApi.tracking().log(data);
}

export const useTrackEvent = (name: string, artist: ArtistModel, tour: TourModel = undefined, notes = "") => {

    useLayoutEffect(() => {
        const user = new CurrentUserState();

        trackEvent(new TrackingEventModel({
            name: name,
            email: user.email,
            artistId: !!artist ? artist.id : undefined,
            artistName: !!artist ? artist.name : undefined,
            tourId: !!tour ? tour.id : undefined,
            tourName: !!tour ? tour.name : undefined,
            notes
        }));

    }, [artist, tour])
}
