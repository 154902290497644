import * as React from 'react';
import { getCurrentUrlOrigin, getUrlArgs, removeParameterFromUrl } from '../../common/urlUtils';
import { useState } from 'react';
import { leftLongArrow } from '../../common/components/Entities';
import { LinkNavigation } from '../../common/components/Navigation';
import "./returnToNavLinks.less";


// provides some tools for a "back" button.. so the user can easily go back to the previous tool
// eventually we should replace all of this sort of functionality with thisthese controls

const pathName = "spath";
const nameName = "sname";

export interface ReturnToNavLinksInfo {
    name: string;
    path: string;
}

export const ReturnToNavLink = () => {

    // keep the first time in state.. this should only be set once
    // so we can navigate around without losing this initial info
    //    const [returnInfo, setReturnInfo] = useState(undefined as ReturnToNavLinksInfo);

    const returnInfo = React.useMemo(() => currentReturnToUrl(), [])


    if (!returnInfo || !returnInfo.path) return null;

    return <div className="return-to-nav-links no-print">
        <br />
        <LinkNavigation
            to={returnInfo.path}>
            {leftLongArrow} Back to {returnInfo.name}
        </LinkNavigation>
        {/* <br /> */}
        <br />
    </div>
}

export const getReturnToNavArguments = (backToName: string) =>
    `${nameName}=${backToName}&${pathName}=${encodeURI(window.location.pathname)}`;

export const removeReturnToNavArguments = (url: string) => removeParameterFromUrl(removeParameterFromUrl(url, pathName), nameName);

export const currentReturnToUrl = () => {
    const args = getUrlArgs();
    return (!!args[nameName] && !!args[pathName])
        ? { name: decodeURIComponent(args[nameName]), path: args[pathName] }
        : undefined;
}