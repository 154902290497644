import { SubcategoryInvalidReason } from '../../api/services';
import { formatDateReadable } from '../../common/dateUtils';
import { TourModel, SubcategoryBudgetModel } from '../../api/services';
import { VenueModel } from '../../api/services';
import { ActionListItem } from '../budgeting/shared/LineOptionsMenu';

// export const currentTour = (props: TourTracksProps) => props.tour.tours.find(t => t.id == props.tour.id);
// export const currentTourName = (props: TourTracksProps) => {
//     const t = currentTour(props);
//     return t ? t.name : "";
// }

export const formatVenueLocationSummary = (venue: VenueModel) =>
    !venue
        ? "n/a"
        : (!venue.name ? "" : venue.name) + ", " + formatVenueAddressShort(venue);

export const formatVenueAddressLocality = (venue: VenueModel) =>
    !venue ? "n/a" : venue.locality || venue.city || venue.state || venue.country || venue.name || "n/a";


export const formatVenueAddress = (venue: VenueModel) => {
    if (!venue) return "n/a";
    let address = venue.locality || "";
    if (!!venue.state && venue.state != venue.city && venue.state != venue.locality) {
        if (!!address) address += ", ";
        address += venue.state;
    }
    const hasMore = !venue.state && !!venue.country;
    if (!!address && hasMore) address += `, `
    if (hasMore) address += venue.country;
    return address.trim();
}

export const formatVenueAddressShort = (venue: VenueModel) => {
    if (!venue) return "n/a";
    let address = venue.locality || venue.state || venue.country;
    if (!!address && address != venue.country) address += ", " + venue.country;
    return address || "n/a";
}

export const formatTourDateSummary = (tour: TourModel) => {
    // debugger;
    if (!tour.firstDateISO || !tour.lastDateISO) return "No shows";
    if (tour.firstDateISO == tour.lastDateISO) return formatDateReadable(tour.firstDateISO);
    return `${formatDateReadable(tour.firstDateISO)} to ${formatDateReadable(tour.lastDateISO)}`
}



export const intToRGB = (index: number, length: number, offset = 0, spread: number = 193456) => {

    const value = ((spread * index) + offset) / length;

    //credit to https://stackoverflow.com/a/2262117/2737978 for the idea of how to implement
    var blue = Math.floor(value % 256);
    var green = Math.floor(value / 256 % 256);
    var red = Math.floor(value / 256 / 256 % 256);

    return "rgb(" + red + "," + green + "," + blue + ")";
}


export const getSubcategoryRowClassName = (sc: SubcategoryBudgetModel) => `${!!sc.fromPresetId ? "uses-preset-row" : ""} ${!!sc.invalidReason ? "row-invalid" : ""} ${sc.deactivated || !!sc.invalidReason ? "inactive" : ""}`

export const getSubcategoryInvalidReasonText = (r: SubcategoryInvalidReason) => {
    switch (r) {
        case SubcategoryInvalidReason.AllocatedShowMissing: return "Sub-category is invalid becuase the allocated show is hidden";
    }
    return ""
}

export const getRockPersonName = (i: number) => {
    const names = ["George", "Ringo", "Sheena", "Yoko", "Janis", "Lily", "Paul", "Axel", "Ozzy", "John", "Lars", "Angus", "Sinead", "PJ", "Iggy", "Miles"];
    return names[i % names.length];
};

export const getHoursToFriendlyTimeDisplay = (hours: number) => {
    const hoursRound = Math.round(hours);
    if (hours < 24) return hoursRound + " hour" + (hoursRound == 1 ? "" : "s");
    const days = hours / 24;
    const daysRound = Math.round(days);
    const remainingHours = (days - daysRound) * 24;
    if (days < 1) return Math.round(remainingHours) + " hour" + (hoursRound == 1 ? "" : "s");
    if (days < 7) return daysRound + " day" + (daysRound == 1 ? "" : "s");
    const weeksRound = Math.floor(days / 7);
    const remainingDays = days - (weeksRound * 7);
    return weeksRound + " week" + (weeksRound == 1 ? "" : "s") + (!!remainingDays ? (remainingDays + " days") : "");
}