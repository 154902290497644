import * as React from "react";

export const XSymbol = () => <>&times;</>

// one of these?
// https://stackoverflow.com/questions/658044/tick-symbol-in-html-xhtml

export const TickSymbol = () => <>&#x2713;</>

export const BackArrow = () => <>&larr;</>

export const upArrow = "▲"
export const downArrow = "▼"

export const upArrowUnfilled = <span>&#8593;</span>
export const downArrowUnfilled = <span>&#8595;</span>


export const ClosedArrow = () => <span>&gt;</span>
export const OpenArrow = () => <div style={{ transform: "rotate(90deg)", display: "inline-block" }}>&gt;</div>


export const LeftLongArrow = () => <>&larr;</>
export const leftLongArrow = "←";