import * as H from 'history';
import * as React from "react";
import { CSSProperties, ReactElement } from 'react';
import { Switch } from 'react-router';
import { NavLink, Route } from 'react-router-dom';
import { ActionButton, GreenActionButton, LinkButton, PurpleActionButton } from './Buttons';
import { GenericCard } from './Cards';
import { WaitLoader } from "./Loader";
import "./navigation.less";

export const LinkButtonNavigation =
    (props: {
        label: string,
        to: H.LocationDescriptor,
        disabled?: boolean,
        className?: string,
        onClick?: () => void
    }) =>
        <LinkNavigation to={props.to} className={props.className} >
            <LinkButton
                onClick={props.onClick}
                disabled={props.disabled}
            >{props.label}</LinkButton>
        </LinkNavigation>

export const ActionLinkNavigation =
    (props: {
        id?: string,
        label: string,
        to: H.LocationDescriptor,
        disabled?: boolean,
        loading?: boolean,
        className?: string,
        onClick?: () => void,
        secondary?: boolean,
        buttonStyle?: CSSProperties,
    }) =>
        <LinkNavigation
            id={props.id}
            to={props.to}
            disabled={props.disabled || props.loading}
            className={props.className}
        >
            <ActionButton
                label={!!props.loading ? <WaitLoader size={14} /> : props.label}
                disabled={props.disabled}
                onClick={props.onClick}
                secondary={props.secondary}
                style={props.buttonStyle}
            />
        </LinkNavigation >

export const GreenActionLinkNavigation =
    (props: {
        id?: string,
        label: string,
        to: H.LocationDescriptor,
        disabled?: boolean,
        className?: string,
        onClick?: () => void
    }) =>
        <LinkNavigation
            id={props.id}
            to={props.to}
            disabled={props.disabled}
            className={props.className}
        >
            <GreenActionButton
                label={props.label}
                disabled={props.disabled}
                onClick={props.onClick} />
        </LinkNavigation >

// TODO need to clean up all this duplication at some point
export const PurpleActionLinkNavigation =
    (props: {
        label: string,
        to: H.LocationDescriptor,
        disabled?: boolean,
        className?: string,
        onClick?: () => void
    }) =>
        <LinkNavigation
            to={props.to}
            disabled={props.disabled}
            className={props.className}
        >
            <PurpleActionButton
                label={props.label}
                disabled={props.disabled}
                onClick={props.onClick} />
        </LinkNavigation >

export const LinkNavigation = (props: {
    id?: string,
    to: H.LocationDescriptor,
    scrollToTop?: boolean,
    disabled?: boolean,
    className?: string,
    title?: string,
    hideLinkStyle?: boolean,
    children,
    styles?,
    secondary?: boolean,
}) =>
    <NavLink
        id={props.id}
        // exact={true}
        to={props.to}
        className={(props.className || "") + (props.hideLinkStyle ? " no-link-style " : "") + (props.disabled ? " disabled" : "")}
        title={props.title}
        replace={false}
        activeClassName="active"
        style={{ ...props.styles }}
        onClick={(e) => {
            if (props.disabled) e.preventDefault();
            if (props.scrollToTop) window.scrollTo(0, 0);
        }}
    >
        {props.children}
    </NavLink >

// export interface GoNextResponse {
//     ok: boolean;
//     toRoute?: string;
// }

export interface CardNavProps {
    // path: string,
    backTo: string,
    goTo?: string;
}

// -----------------------------------------------------------------
// TODO
// all the following can become generic and moved away from the BL


export interface CardFormProps {
    onOk?: () => Promise<boolean>,
    okText?: string,
    saveDisabled?: boolean,
    children: ReactElement<any>
    disableRedirectOnSave?: boolean;
}

export class CardForm extends React.Component<CardNavProps & CardFormProps, { saving: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            saving: false
        };
    }


    handleSave = (history) => {
        const props = this.props;
        // debugger;
        this.setState({ saving: true }, () => {
            props.onOk()
                .then(ok => {
                    this.setState({ saving: false }, () => {
                        if (ok && !props.disableRedirectOnSave) history.push(props.goTo || props.backTo);
                    })
                })
                .catch(e => {
                    this.setState({ saving: false });
                })
        })
    }

    render() {

        const props = this.props;



        return <Switch>

            <Route render={({ history }) => (
                <form onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleSave(history);
                }}>
                    <GenericCard {...props}>
                        <div>
                            {props.children}
                            <div style={{ textAlign: "right", marginTop: 20 }}>
                                <LinkButtonNavigation
                                    to={props.backTo}
                                    label="Cancel" />
                                <div className="vertical-spacer"></div>
                                <ActionButton
                                    label={props.okText || "Save"}
                                    type="submit"
                                    disabled={props.saveDisabled || this.state.saving}
                                // onClick={() => this.handleSave(history)} 
                                />
                                {/* <button type="submit" style={{ display: "none" }} /> */}
                            </div>

                        </div>
                    </GenericCard>
                </form>
            )} />
        </Switch >

    }
}

// TODO this could easily be generic, ie no property string names
export interface FormProps<T> {
    onPropertyChange: (property: string, value: any) => void;
    data: T;
    errors: Map<string, string>;
}

export interface FormContainerState<T> {
    data: T;
    errors: Map<string, string>;
}

