import * as React from 'react';
import { useState } from 'react';
import { CustomerFeedbackModel } from '../../api/services';
import { TTApi } from '../../api/ttApi';
import { CurrentUserState } from '../../auth/currentUserState';
import { CloseSave } from '../../common/components/Buttons';
import { SystemMessage } from '../../system/systemMessage';
import "./feedbackForm.less";


export interface FeedbackFormProps {
    onClose: () => void,
}

export const FeedbackForm = (props: FeedbackFormProps) => {

    const [text, setText] = useState("");

    const [saving, setSaving] = useState(false);

    const sendFeedback = async () => {

        const user = new CurrentUserState();

        setSaving(true);

        try {
            await TTApi.feedback().sendFeedback(new CustomerFeedbackModel({
                name: user.name,
                email: user.email,
                url: window.location.pathname,
                message: text
            }));

            SystemMessage.showSuccess("Thanks for the feedback!")

            props.onClose();
        }
        catch{
            SystemMessage.showError("There was a problem sending this feedback")
        }
        finally {
            setSaving(false);
        }
        // setSent(true);

    }

    return <div className="feedback-form">
        <p>We'd love to hear your feedback.<br /> Please tell us what you think below.</p>
        <textarea
            autoFocus
            maxLength={20000}
            placeholder="What do you like?&#x0a;What could we improve?"
            onChange={e => setText(e.target.value)}
        />
        <CloseSave
            saveText={saving ? "Sending" : "Send"}
            onSave={sendFeedback}
            onClose={props.onClose}
            saveDisabled={!text.length || saving}
        />
        {/* </>
        } */}
    </div>

}