import * as React from 'react';
import { GenericCard } from '../../common/components/Cards';
import { useState } from 'react';
import { LinkNormal } from '../../common/components/Buttons';
import { ConfirmBox } from '../../common/components/Modals';
import { TTApi } from '../../api/ttApi';
import { Redirect } from 'react-router';

export interface NonAdminSubscriptionBlockProps {
    artistId: string;
}

export const NonAdminSubscriptionBlock = (props: NonAdminSubscriptionBlockProps) => {

    const [checkIsSureRemove, setCheckIsSureRemove] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);

    if (isRemoved) return <Redirect to="/" />

    const handleRemoveAccountForce = async () => {
        await TTApi.users().removeCurrentUser(props.artistId);

        setIsRemoved(true);
    }

    return <div className="non-admin-subscription-block readable-content">
        <br />
        <br />
        <br />
        <GenericCard>
            <h1>Access Denied</h1>
            <p>This artist's multi-user feature has been disabled. Contact the Artist Manager for more information.</p>
            <br />
            <p><LinkNormal onClick={() => setCheckIsSureRemove(true)}>Remove this artist from my list</LinkNormal></p>
        </GenericCard>
        {checkIsSureRemove &&
            <ConfirmBox
                // title="Blaa hello"
                onCancel={() => setCheckIsSureRemove(false)}
                onOk={handleRemoveAccountForce}
            >
                Are you sure? You will no longer be able to access this artist
                </ConfirmBox>
        }
    </div>

}