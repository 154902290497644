import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RouteComponentProps } from 'react-router-dom';
import { TTApi } from '../api/ttApi';
import AuthService from '../auth/authService';
import { CurrentUserState } from '../auth/currentUserState';
import { SystemMessage } from '../system/systemMessage';


export default class Examples extends React.Component<RouteComponentProps<{}>, {}> {

    constructor(props) {
        super(props);
        this.state = { isToggleOn: true };

        // This binding is necessary to make `this` work in the callback
        this.logout = this.logout.bind(this);
    }

    logout() {
        var auth = new AuthService();
        auth.logout();
        this.props.history.push('/');

    }

    message() {
        SystemMessage.showInfo("hello!!!!");
    }

    yo() {
        SystemMessage.showSuccess("zzzzzzz");
    }

    public render() {


        const user = new CurrentUserState();

        const logoutButton = user.isAuthenticated()
            ? <span><br /><a onClick={this.logout} href="#">Logout</a></span>
            : "";

        const message = <button onClick={this.message}>info</button>;

        return <div>
            <h1>Hello, world!!!!!</h1>
            <h2>Testing</h2>
            <p>.net core 3.1!!!</p>
            <div className="test1">
                <div className="test2">blaaa</div>
            </div>
            <p>Hello {user.displayName}</p>
            <a href="/login">Login</a>
            <a href="/signup">Sign up</a>
            <p>{logoutButton}</p>
            <p>{message}</p>
            <p>Welcome to your new single-page application, built with:</p>
            <ul>
                <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
                <li><a href='https://facebook.github.io/react/'>React</a>, <a href='http://redux.js.org'>Redux</a>, and <a href='http://www.typescriptlang.org/'>TypeScript</a> for client-side code</li>
                <li><a href='https://webpack.github.io/'>Webpack</a> for building and bundling client-side resources</li>
                <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
            </ul>
            <p>To help you get started, we've also set up:</p>
            <ul>
                <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
                <li><strong>Webpack dev middleware</strong>. In development mode, there's no need to run the <code>webpack</code> build tool. Your client-side resources are dynamically built on demand. Updates are available as soon as you modify any file.</li>
                <li><strong>Hot module replacement</strong>. In development mode, you don't even need to reload the page after making most changes. Within seconds of saving changes to files, rebuilt React components will be injected directly into your running application, preserving its live state.</li>
                <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and the <code>webpack</code> build tool produces minified static CSS and JavaScript files.</li>
                <li><strong>Server-side prerendering</strong>. To optimize startup time, your React application is first rendered on the server. The initial HTML and state is then transferred to the browser, where client-side code picks up where the server left off.</li>
            </ul>

            <span>--------------</span>

            <a onClick={() => {
                TTApi.artists().sendTestEmail();
            }}>send test email</a>

            <span>--------------</span>

            <Typeahead
                labelKey="name"
                multiple={false}
                options={["fghfgh", "dfgdfg", "yyy", "rrr", "jjj", "hhh", "fff"]}
                placeholder="Choose a state..."
            />
            <span>--------------</span>


        </div>
    }
}
