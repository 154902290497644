import { FeatureFlags } from './featureFlags';

// TODO: this order could easily be driven from the back end to save duplication
export const categoriesInOrder = [
    "Performance",
    "Other Income",

    "Show Costs",
    "Production",
    "Personnel",
    "Travel",
    "Marketing",
    "Admin",
    "Commissions",

    "Merch Income",
    "Merch Costs",

]

export const merchCostCategoryName = "Merch Costs";


const categoryOrderMap = new Map<string, number>();
categoriesInOrder.forEach((c, i) => categoryOrderMap.set(c, i));



export const getCategoryOrder = (name) =>
    categoryOrderMap.has(name) ? categoryOrderMap.get(name) : 999


// orders a generic list by the preferred category order
export function orderByCategory<T>(list: T[], categoryNameFun: (o: T) => string): T[] {
    const getOrder = (o: T) => {
        const name = categoryNameFun(o);
        return getCategoryOrder(name);
    }

    if (!list) return list;
    return list.sort((a, b) => getOrder(a) - getOrder(b));
}

const fixSubcatOrder = (sc) => sc == "General" ? "ZZZZZZZZZZZZZZZZZ" : sc;
export const getSubcategoryOrder = (a, b) => fixSubcatOrder(a).localeCompare(fixSubcatOrder(b));


export const getAllowedDeductableCategories = <T>(list: T[], categoryNameFun: (o: T) => string): T[] => {
    const isAllowed = (o: T) => {
        const name = categoryNameFun(o);
        return name != "Merch Costs";
    }

    if (!list) return list;
    return list.filter(isAllowed);
}

export const isMerchCategory = (category: string) => category == "Merch Income" || category == "Merch Costs";

// note: list is categories that don't use contingency
export const hasContingency = (category: string) => {
    if (category == "Personnel") return FeatureFlags.hasPersonnelContingency;
    // check list of things that DON'T have contingencies.. ie true if not in this list
    return !(["Other Income", "Performance", "Merch Income", "Show Costs", "Commissions", "Merch Costs"].find(c => c == category));
}

// asssume these categories are ok to delete if we delete a show that these are assigned to
export const noNeedToWarnAboutShowDelete = ["Show Costs", "Merch Costs", "Merch Income"];


const canHaveAutoPresetsForTour = new Set([
    "Production",
    "Personnel",
    "Travel",
    "Marketing",
    "Admin",
    "Commissions",

    "Merch Costs",
]);
const canHaveAutoPresetsForShow = new Set([
    "Show Costs",

    "Merch Income",
]);

export const canAutomaticallyAddPrests = (categoryName: string, forShow: boolean) =>
    forShow ? canHaveAutoPresetsForShow.has(categoryName) : canHaveAutoPresetsForTour.has(categoryName);


export const getIconImage = (categoryName: string) => {

    const getName = () => {
        switch (categoryName) {
            case "Personnel": return "tourtracks_icons_FA_11_icons_multiusers";
                break;
            case "Production": return "tourtracks_icons_FA_01_icons_production";
                break;
            case "Commissions": return "tourtracks_icons_FA_06_icons_commission";
                break;
            case "Merch Costs": return "tourtracks_icons_FA_09_icons_merchsales";
                break;
            case "Merch": return "tourtracks_icons_FA_09_icons_merchsales"; //lazy hmmm
                break;
            case "Show Costs": return "tourtracks_icons_FA_02_icon_showcosts";
                break;
            case "Travel": return "tourtracks_icons_FA_03_icons_travelcosts";
                break;
            case "Marketing": return "tourtracks_icons_FA_04_icons_marketing-costs";
                break;
            case "Admin": return "tourtracks_icons_FA_05_icons_admincosts";
                break;

                return "";
        }
    }

    return "assets/settingsIcons/" + getName() + ".svg";
}