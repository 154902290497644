import AuthService from '../auth/authService';
import { CurrentUserState } from '../auth/currentUserState';
import { signInOrSignUp, tempSaveCurrentBrowserPath } from '../auth/urlRedirect';
import { SystemMessage } from '../system/systemMessage';
import { CurrentArtist } from '../tourtracks/artist/currentArtist';
import { AppDataClient, ArtistBudgetPresetsClient, ArtistClient, ArtistCustomSubcategoriesClient, ArtistReportsClient, CustomSubcategoryModel, EventTrackingClient, FeedbackClient, HelpTipsClient, PublicShareClient, QuickBooksClient, SubscriptionsClient, SuperAdminClient, SystemClient, TourBudgetClient, TourClient, TourFinancialsClient, TourReportsClient, UserClient } from './services';

export class TTApi {

    private static async fileUpload(url: string, file: File): Promise<any> {

        let formData = new FormData();
        formData.append("file", file);

        const http = this.getHttp();
        const r = await http.fetch(url, {
            body: formData,
            headers: {},
            method: "POST",
        });

        return r;

    }

    private static getHttp() {
        const user = new CurrentUserState();

        return {
            fetch: (url: RequestInfo, init: RequestInit): Promise<Response> => {

                init.headers["Authorization"] = "Bearer " + user.accessToken;
                // always send the artist id if we have one.. then we
                // can do all sorts of seucrity checking like subscription plans etc
                init.headers["ArtistId"] = CurrentArtist.get();

                return fetch(url, init)
                    .then((r: Response) => {
                        if (r.status == 401) {
                            const auth = new AuthService();
                            tempSaveCurrentBrowserPath();

                            console.log("API 404");

                            if (user.loginExpired()) {
                                console.log("API LOGIN EXPIRED");
                                auth.renew().then(() => {
                                    if (!user.isAuthenticated()) {
                                        auth.login(signInOrSignUp());
                                    }
                                    else {
                                        console.log("API RENEW SUCESS");
                                    }
                                }).catch(r => {
                                    console.log("API RENEW ERROR");
                                    auth.login(signInOrSignUp());
                                });
                            }
                            else {
                                auth.login(signInOrSignUp());
                            }
                        }

                        if (!r.ok) {
                            r.json().then(d => {
                                SystemMessage.showError(d.error);
                            })
                        }
                        return r;
                    })
                    .catch(ex => {
                        SystemMessage.showError(ex);
                        return ex;
                    });
            }
        };
    }

    static appData() {
        return new AppDataClient("", this.getHttp());
    }

    static artists() {
        return new ArtistClient("", this.getHttp());
    }

    static artistCustomSubcats() {
        return new ArtistCustomSubcategoriesClient("", this.getHttp());
    }
    // note, this endpoint isn't generated becuase the generated version doesn't seem to do fileuploads properly
    static async artistCustomSubcatsProcess(file: File): Promise<CustomSubcategoryModel[]> {
        const r = await this.fileUpload(`/api/v1/artistcustomsubcategories/extractsubcategoriesfromfile`, file);

        const data = await r.json();
        return data;
    }


    static feedback() {
        return new FeedbackClient("", this.getHttp());
    }

    static tracking() {
        return new EventTrackingClient("", this.getHttp());
    }

    static tours() {
        return new TourClient("", this.getHttp());
    }

    // note, this endpoint isn't generated because the generated version doesn't seem to do fileuploads properly
    static async transactionUpload(tourId: string, file: File): Promise<any> {
        await this.fileUpload(`/api/v1/tourfinancials/uploadTransactions?tourId=${tourId}`, file);
    }

    static tourReports() {
        return new TourReportsClient("", this.getHttp());
    }

    static artistReports() {
        return new ArtistReportsClient("", this.getHttp());
    }

    static tourBudgets() {
        return new TourBudgetClient("", this.getHttp());
    }

    static artistBudgetPresets() {
        return new ArtistBudgetPresetsClient("", this.getHttp());
    }

    static users() {
        return new UserClient("", this.getHttp());
    }

    static financials() {
        return new TourFinancialsClient("", this.getHttp());
    }

    static subscriptions() {
        return new SubscriptionsClient("", this.getHttp());
    }

    static helpTips() {
        return new HelpTipsClient("", this.getHttp());
    }

    static quickbooks() {
        return new QuickBooksClient("", this.getHttp());
    }

    static publicShare() {
        return new PublicShareClient("", this.getHttp());
    }

    static superAdmin() {
        return new SuperAdminClient("", this.getHttp());
    }

    static system() {
        return new SystemClient("", this.getHttp());
    }

}