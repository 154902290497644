import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { WaitLoader } from '../../../common/components/Loader';
import { getUrlArgs } from '../../../common/urlUtils';
import { QuickBooksAPI, useQuickbooksGetAuthToken } from './hooks';

type Props = RouteComponentProps<{}>;

// export interface QuickBooksCallbackProps {
// }

// QBO auth will return to this page with a bunch of info
// at this point we grab the code and realm from the url
// then do another call to grab the access token, which we then store for later
// once that's all good we go back to wherever we came from
export const QuickBooksCallback = (props: Props) => {

    const url = QuickBooksAPI.getReturnUrl();
    const args = getUrlArgs();
    const { code, realmId } = args;

    QuickBooksAPI.setCodeAndRealm(code, realmId);

    const [loading, result] = useQuickbooksGetAuthToken(code, realmId);

    //    if (!loading && !result) SystemMessage.showInfo("");

    if (!loading && !!result) {
        QuickBooksAPI.setAuthToken(result.accessToken, result.accessTokenExpiresInSeconds)

        return <Redirect to={url} />
    }

    return !!loading &&
        <div style={{ width: "100%", textAlign: "center" }}>
            <br /><br />
            Authenticating with QuickBooks ...
            <br /><br />
            <WaitLoader size={30} />
        </div>
}


