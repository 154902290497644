import { CurrentUserState } from "./currentUserState";
import { globalConfig } from "../config/globalConfig";

export type LoginScreenType = "login" | "signUp" | "forgotPassword";

// this is coming from a cdn 
// .. so to avoid having to include typings or (shudder) the npm package
// just do this
declare var auth0: any;

export default class AuthService {

    scope = 'openid profile name email offline_access';
    responseType = 'token id_token';
    // redirectPath = "authcallback";

    auth0 = new auth0.WebAuth({
        domain: globalConfig.auth0.domain, // 'tourtourtracks.au.auth0.com',
        clientID: globalConfig.auth0.clientId, // 'MqcaRbHi61xraeDOIYnUdf5Ryv5CnD1p',
        redirectUri: globalConfig.auth0.redirectUri, // 'http://localhost:51195/callback',
        audience: globalConfig.auth0.audience, // 'https://tourtourtracks.au.auth0.com/userinfo',
        responseType: this.responseType,
        scope: this.scope
    });

    currentUser: CurrentUserState;

    constructor() {
        this.currentUser = new CurrentUserState();
    }


    login(screen: LoginScreenType = "login") {
        // debugger;
        this.auth0.authorize({
            // nofications made to the template so that this will work
            initial_screen: screen
        });
    }

    async renew(): Promise<boolean> {
        const me = this;

        // NOTE: safari will break this
        // https://auth0.com/docs/api-auth/token-renewal-in-safari

        return new Promise<boolean>((resolve, reject) => {

            // only do this is the user is already authenticated
            if (this.currentUser && this.currentUser.nonce) {

                this.auth0.checkSession({}, async (err, authResult) => {
                    console.log("CHECK SESSION RESULT");
                    // console.log(err);
                    // console.log(authResult);
                    if (!!err) me.login();
                    if (!!authResult) {
                        await me.setSession(authResult);
                        console.log("TOKEN REFRESHED")
                        resolve(true);
                    }
                    else {
                        reject(err);
                    }
                });
            }

        });

    }



    async handleAuthentication(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                    resolve();
                } else {
                    console.log(err);
                    reject(err);
                }
            });
        });
    }

    private setSession(authResult) {
        // debugger;

        // Set the time that the access token will expire at
        // authResult.expiresIn is in seconds
        let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        );

        const payload = authResult.idTokenPayload;

        this.currentUser.emailVerified = payload.email_verified;
        this.currentUser.name = payload.name;
        this.currentUser.familyName = payload.family_name;
        this.currentUser.givenName = payload.given_name;
        this.currentUser.nickname = payload.nickname;
        this.currentUser.email = payload.email;
        this.currentUser.picture = payload.picture;
        this.currentUser.userId = payload.sub;
        this.currentUser.accessToken = authResult.accessToken;
        this.currentUser.idToken = authResult.idToken;
        this.currentUser.loginExpires = expiresAt;
        this.currentUser.nonce = authResult.idTokenPayload.nonce;

        // custom 
        this.currentUser.signupDate = authResult.idTokenPayload["https://tourtracks.co/created_at"];

    }

    async resetPassword(): Promise<string> {
        // return this.httpClient.fetch('values');
        const me = this;

        return new Promise<string>(function (resolve, reject) {

            me.auth0.changePassword({
                client_id: globalConfig.auth0.clientId,
                email: me.currentUser.email,
                connection: "Username-Password-Authentication"
            }, function (err, resp) {
                if (err) {
                    console.log(err.message);
                    // debugger;
                    reject("Error sending password link");
                } else {
                    console.log(resp);
                    resolve(resp);
                }
            });


        });
    }


    logout() {
        this.currentUser.clear();
        window.location.replace("http://www.tourtracks.co/");
    }

}

export class AuthConstants {

    static KeyLastPageVisit = "AUTHLASTPAGEVISIT";
    static KeySignupToken = "SIGNUPTOKEN";

} 
