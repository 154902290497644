import * as React from 'react';
import { Route, Switch } from "react-router";
import { ArtistSubscriptionAccessEnum, ArtistSubscriptionStatusModel, SharingAccessEnum } from '../../api/services';
import { LinkNavigation } from '../../common/components/Navigation';
import { CopyURLToCliboardButton } from '../../common/components/ReportShareButton';
import { isActualsReportsViewRole } from '../helpers/userUtils';
import { TTPaths } from '../navigation/paths';
import { TourTracksProps } from '../TourTracksMainScreen';
import "./mainHeading.less";
import { LinkPublicShareButton } from '../publicShare/PublicShareButton';


const LinkShareButton = (props: { subscription: ArtistSubscriptionStatusModel }) =>
    <>
        {!!props.subscription && props.subscription.access.find(s => s == ArtistSubscriptionAccessEnum.MultiUser) &&
            <span className="no-print">&nbsp;&nbsp;&nbsp;<CopyURLToCliboardButton /></span>
        }
    </>


export const MainHeading = (props: TourTracksProps) => {

    const { tourTracks } = props;
    const { state } = tourTracks;
    const { artist, tour, subscription, appData } = state;

    const a = artist;
    const t = tour;
    const s = subscription;
    // const appData = props.appData;

    if ((!a && !t) || !appData) return null;

    const canViewActualsReports = isActualsReportsViewRole(appData.artistRoles, a.id);

    return <div className="main-heading-section">

        {/* print artist/tour headings */}
        <div className="print-only print-artist-tour-heading">
            {!!a &&
                <h1>
                    {a.name}
                    {!!t && <span> - {t.name}</span>}
                </h1>
            }
            {!!a && !!a.adminImageUrl && <img src={a.adminImageUrl} />}
        </div>


        {/* main site artist/tour headings */}
        <Switch>
            {/* mobile nav headings  */}
            <Route path="/">
                <div className="top-info-heading">
                    {/* mobile artist/tour heading */}
                    <div className="show-small-screen no-print" style={{ width: "100%" }}>
                        {!!a &&
                            <LinkNavigation to={TTPaths.artistHome(a.id)}>
                                <div className="main-heading">
                                    <div className="main-heading-withpic">
                                        {!!a.adminImageUrl && <img src={a.adminImageUrl} />}
                                        <div>{a.name}</div>
                                    </div>
                                </div>
                            </LinkNavigation>
                        }
                        {!!a && !!t &&
                            <LinkNavigation to={TTPaths.tourHome(a.id, t.id)}>
                                <div className="main-heading">
                                    <div>{t.name}</div>
                                </div>
                            </LinkNavigation>
                        }
                    </div>

                    {/* desktop left side page headings */}
                    <div className="hide-small-screen dash-heading-left">
                        {!!a &&
                            <Switch>
                                <Route exact path={TTPaths.artistHome()}>
                                    <h1>Artist Home</h1>
                                </Route>
                                <Route exact path={TTPaths.newTour()}>
                                    <h1>Artist Settings</h1>
                                </Route>
                                <Route exact path={TTPaths.artistSettings()}>
                                    <h1>Artist Settings</h1>
                                </Route>
                                <Route exact path={TTPaths.artistSubcategories()}>
                                    <h1>Subcategory Editor</h1>
                                </Route>
                                <Route path={TTPaths.artistEdit()}>
                                    <h1>Artist Settings</h1>
                                </Route>
                                <Route path={TTPaths.budgetPresetsHome()}>
                                    <h1>Artist Settings</h1>
                                </Route>
                                <Route exact path={TTPaths.reportArtistReport()}>
                                    <h1>Artist Report <LinkShareButton subscription={s} /></h1>
                                </Route>
                                <Route exact path={TTPaths.artistUsers()}>
                                    <h1>Manage Users</h1>
                                </Route>
                                <Route exact path={TTPaths.reportArtistShowSearch()}>
                                    <h1>Show Search <LinkShareButton subscription={s} /></h1>
                                </Route>
                                <Route exact path={TTPaths.tourHome()}>
                                    <h1>Tour Home</h1>
                                </Route>
                                {/* <Route path={TTPaths.budgetingHome()}>
                                    <h1>Budget</h1>
                                </Route> */}
                                <Route path={TTPaths.reportsTourBudgetGridReport()}>
                                    <h1>Budget Grid Report <LinkShareButton subscription={s} /></h1>
                                </Route>




                                {/* all these have the same heading... we use tabs inside the reports for nav */}
                                <Route path={TTPaths.reportsTourMegaReport()}>
                                    <div className="tourreporthead">
                                        {!!t &&
                                            <h1>Tour Reports
                                                <LinkPublicShareButton subscription={s} artistId={a.id} tourId={t.id} access={SharingAccessEnum.TourFinancialsReport} />
                                            </h1>
                                        }
                                    </div>
                                </Route>
                                <Route path={TTPaths.reportsTourMegaTransactionsReport()}>
                                    <div className="tourreporthead">
                                        {!!t &&
                                            <h1>Tour Reports
                                                <LinkPublicShareButton subscription={s} artistId={a.id} tourId={t.id} access={SharingAccessEnum.TourTransactionsReport} />
                                            </h1>
                                        }
                                    </div>
                                </Route>
                                <Route path={TTPaths.reportsTourCommissionsReport()}>
                                    <div className="tourreporthead">
                                        {!!t &&
                                            <h1>Tour Reports
                                                {/* <LinkPublicShareButton subscription={s} artistId={a.id} tourId={t.id} access={SharingAccessEnum.tourtra} /> */}
                                            </h1>
                                        }
                                    </div>
                                </Route>


                                {/* 
                                <TourActualsReportHeading
                                    {...props}
                                    canViewActualsReports={canViewActualsReports}
                                /> */}


                                <Route path={TTPaths.reportsTourMegaItineraryReport()}>
                                    {!!t &&
                                        <h1>Itinerary Report <LinkPublicShareButton subscription={s} artistId={a.id} tourId={t.id} access={SharingAccessEnum.TourItineraryReport} /></h1>
                                    }
                                </Route>
                                <Route path={TTPaths.reportsTourMegaBudgetReport()}>
                                    {!!t &&
                                        <h1>Budget Report <LinkPublicShareButton subscription={s} artistId={a.id} tourId={t.id} access={SharingAccessEnum.TourBudgetReport} /></h1>
                                    }
                                </Route>

                                <Route path={TTPaths.artistSubscription()}>
                                    <h1>Subscription</h1>
                                </Route>
                            </Switch>
                        }
                        {/* {!!t &&
                            <h2 className="main-heading">
                                {!!t && t.name}
                            </h2>
                        } */}
                    </div>


                    {/* desktop right side headings */}
                    {/* <div className="hide-small-screen dash-heading-report">
                        {!!a &&
                            <Switch>
                                <Route path={TTPaths.reportArtistDashboard()}>
                                    <DesktopCompanyLogo adminImageUrl={a.adminImageUrl} />
                                </Route>
                                <Route exact path={TTPaths.reportsTourMegaReport()}>
                                    <DesktopCompanyLogo adminImageUrl={a.adminImageUrl} />
                                </Route>
                            </Switch>
                        }
                    </div> */}

                </div>
            </Route>
        </Switch>
        {/* mobile report headings */}
        <Switch>
            <Route path={TTPaths.reportArtistShowSearch()}>
                <div className="main-heading show-small-screen">Show Search</div>
            </Route>
            <Route path={TTPaths.reportArtistReport()}>
                <div className="main-heading show-small-screen">Artist Report</div>
            </Route>
            <Route path={TTPaths.reportsTourBudgetGridReport()}>
                <div className="main-heading show-small-screen">Budget Grid Report</div>
            </Route>
            <Route path={TTPaths.reportsTourMegaItineraryReport()}>
                <div className="main-heading show-small-screen">Itinerary Report</div>
            </Route>
            <Route path={TTPaths.reportsTourMegaBudgetReport()}>
                <div className="main-heading show-small-screen">Budget Report</div>
            </Route>
            <Route path={TTPaths.reportsTourMegaReport()}>
                <div className="main-heading show-small-screen">Tour Report</div>
            </Route>
            <Route path={TTPaths.reportsTourMegaTransactionsReport()}>
                <div className="main-heading show-small-screen">Transactions Report</div>
            </Route>
            <Route path={TTPaths.artistSubscription()}>
                <div className="main-heading show-small-screen">Subscription</div>
            </Route>
            {/* <Route path={TTPaths.reportsTourProfitLoss()}>
                <div className="main-heading show-small-screen">Profit &amp; Loss</div>
            </Route>
            <Route path={TTPaths.reportsTourProfitLossExpanded()}>
                <div className="main-heading show-small-screen">Transaction List</div>
            </Route>
            <Route path={TTPaths.reportsTourDashboard()}>
                <div className="main-heading show-small-screen">Tour Dashboard</div>
            </Route> */}
        </Switch>


    </div>
}