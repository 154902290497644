import * as React from "react";
// import { ClipLoader } from 'halogen';
import Loader from 'react-loader-spinner'

export const WaitLoader = (props: { size?: number }) => {
    const size = (props.size ? props.size : 50);

    // http://samherbert.net/svg-loaders/
    // https://mhnpd.github.io/react-loader-spinner/

    // const [count, setCount] = React.useState(0);


    return <Loader
        type="Puff"
        color="#ccc"
        height={size}
        width={size}
    />

}


export const WaitLoaderFullScreen = (props: {
    dark?: boolean,
    message?: string,
}) =>
    <div style={{

        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999999,
        backgroundColor: props.dark ? "rgba(0,0,0,0.45)" : "none",

    }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <WaitLoader size={50} />
            {!!props.message && <div style={{ marginTop: 20 }}>{props.message}</div>}
        </div>
    </div>
