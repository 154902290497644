import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Auth from './authService';

type Props = RouteComponentProps<{}>;

class Auth0Login extends React.Component<Props, {}> {

    public componentDidMount() {
        var auth = new Auth();
        auth.login();
    }

    public render() {
        return <div>
            <h2>Redirecting to your login page... </h2>
        </div>;
    }
}

export default Auth0Login;