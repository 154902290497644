import { TourTracksState, LoadingStatus } from './state';
import { TourTracksAction } from './actions';
import { AppDataModel } from '../../api/services';


export const initialTourTracksState: TourTracksState = {
    loadingAppData: LoadingStatus.NotLoaded,
    loadingArtist: LoadingStatus.NotLoaded,
    loadingArtistId: undefined,
    loadingTour: LoadingStatus.NotLoaded,
    loadingTourId: undefined,
    appData: new AppDataModel({
        currencies: [],
        categories: [],
        artistRoles: {},
    }),
    subscription: undefined,
    artist: undefined,
    tour: undefined,
};

export const tourTracksReducer = (state: TourTracksState, incomingAction: TourTracksAction): TourTracksState => {
    const action = incomingAction as TourTracksAction;
    switch (action.type) {
        case 'APPDATA/REQUESTED':
            return {
                ...state,
                loadingAppData: LoadingStatus.Loading,
            };
        case 'APPDATA/RECEIVED':
            return {
                ...state,
                appData: action.appData,
                loadingAppData: LoadingStatus.Loaded,
            };
        case 'ARTIST/REQUESTED':
            return {
                ...state,
                subscription: undefined,
                loadingArtist: LoadingStatus.Loading,
                loadingArtistId: action.artistId,
            };
        case 'ARTIST/SELECTED':
            return {
                ...state,
                loadingArtist: LoadingStatus.Loaded,
                loadingArtistId: undefined,
                artist: action.artist,
                subscription: action.subscription,
                tour: undefined,
                loadingTour: LoadingStatus.Loaded,
            };
        case 'TOUR/REQUESTED':
            return {
                ...state,
                loadingTour: LoadingStatus.Loading,
                loadingTourId: action.tourId,
            };
        case 'TOUR/SELECTED':
            return {
                ...state,
                loadingTour: LoadingStatus.Loaded,
                loadingTourId: undefined,
                tour: action.tour,
            };

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || initialTourTracksState;
};
