import * as H from 'history';
import { TTPaths } from '../tourtracks/navigation/paths';

const tempPathKey = "TEMPSAVEDPATH";

export const tempSaveCurrentBrowserPath = () => localStorage.setItem(tempPathKey, document.location.href);

export const redirectToTempPath = (history: H.History) => {
    const url = localStorage.getItem(tempPathKey);
    localStorage.setItem(tempPathKey, "");
    if (!url) {
        // history.push('/');
        window.location.replace("/");
    }
    else {
        // document.location.href = url;

        // redirect like this so the callback 
        // isn't written to the history
        window.location.replace(url);

    }
}

export const signInOrSignUp = () => {
    const invitePath = TTPaths.acceptArtistInvite("BLABLA").replace("BLABLA", ""); //??
    // debugger;
    return document.location.pathname.indexOf(invitePath) != -1 ? "signUp" : "login";
}