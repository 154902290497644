import * as React from 'react';
import "./popupMenu.less";

export interface PopupMenuProps {
    className?: string,
    rounded?: boolean;
    children: any;
}

export const PopupMenu = (props: PopupMenuProps) =>
    <div className={`popup-menu ${props.rounded ? "rounded" : ""} ${props.className || ""}`}>
        {props.children}
    </div>