import * as moment from 'moment';
import { capitalizeFirstLetter } from './stringUtils';

export const DateVeryCompactFormat = "DD MMM";
export const DateCompactFormat = "DD-MMM-YY";
// export const TimeFormat = "h:mm:ss a";
export const TimeFormatCompact = "h:mm a";

export const formatBrowserLocalDateCompact = (dateISO: string) => moment(dateISO).format(DateCompactFormat)
export const formatBrowserLocalTimeCompact = (dateISO: string) => moment(dateISO).format(TimeFormatCompact)

export const formatDateVeryCompact = (dateISO: string) => !dateISO ? "" : moment(ISOToDate(dateISO)).format(DateVeryCompactFormat);
export const formatDateCompact = (dateISO: string) => !dateISO ? "" : moment(ISOToDate(dateISO)).format(DateCompactFormat);
export const formatTimeCompact = (dateISO: string) => !dateISO ? "" : moment(ISOToDateTime(dateISO)).format(TimeFormatCompact);
export const formatDateShort = (dateISO: string) => !dateISO ? "" : moment(ISOToDate(dateISO)).format("D MMM YYYY");
export const formatDateReadable = (dateISO: string) => !dateISO ? "" : moment(ISOToDate(dateISO)).format("ll");
// export const dateToISO = (d: Date) => d.toISOString();


// export const formatDaysFromNow = (d: Date) => "in " + (-(moment().diff(d, 'days') - 1)) + " days";
// export const formatDaysFromNow = (d: Date) => "In " + (-(moment().diff(d, 'days') - 1)) + " days";
// export const formatDaysFromNowMoreThanADay = (d: Date) => "in " + (-(moment().diff(d, 'days') - 1)) + " days";
export const formatPastDateFromNowCoarse = (d: Date) => !d ? "" : moment().diff(d, 'days') < 1 ? "Today" : formatDateFromNow(d);
export const formatFutureDaysFromNow = (d: Date) => {
    console.log(moment().diff(d, 'hours'));
    console.log(moment().diff(d, 'days'));
    // debugger;
    // need to work out the day ourselves becuase moment's rounding is dum
    const hoursDiff = -moment().diff(d, 'hours');
    const monthsDiff = -moment().diff(d, 'months');
    const daysDiff = Math.round(hoursDiff / 24);
    return !d
        ? ""
        : daysDiff < 40
            ? daysDiff < 1
                ? "Today"
                : `in ${daysDiff} days`
            : `in ${monthsDiff} months`;
}
export const formatDateFromNow = (d: Date) => !d ? "" : capitalizeFirstLetter(moment(d).fromNow());
// export const formatDateFromNowPedantic = (d: Date) => {
//     if (!d) return "";
//     if (moment().diff(d, 'days') < 1) return "Today"
//     if (moment().diff(d, 'days') < 31) return moment().diff(d, 'days') + " days";


//     return formatDateFromNow(d);
// }

// converts a js date to an iso string verbatum without thinking about timezones etc
// note on this
// grrr dates
// we don't care about time zones.... if the user says "4/6/2019" then that's what they want
// the input date might come with a timezone.. let's just turn it into an ISO
// string with the exact date they wanted without the timezone component
// grrr dates again
export const dateToISO = (d: Date) =>
    d.getFullYear() + '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
    ('0' + d.getDate()).slice(-2)
    + "T00:00:00.000Z";

//            export const dateToISO = (d: Date) => {
//     // note on this
//     // grrr dates
//     // we don't care about time zones.... if the user says "4/6/2019" then that's what they want
//     // the input date might come with a timezone.. let's just turn it into an ISO
//     // string with the exact date they wanted without the timezone component
//     // grrr dates again
//     const dd = new Date(d);
//     var offsetInMinutes = dd.getTimezoneOffset();
//     dd.setMinutes(dd.getMinutes() - offsetInMinutes);
//     return dd.toISOString();
// }

// converts an iso string to a js date verbatum without thinking about timezones etc
export const ISOToDate = (s: string) => {
    if (!s) return null;
    const d = s.split("T");
    if (d.length == 0) return null;
    const bits = d[0].split("-");
    if (bits.length < 3) return null;
    const n = bits.map(b => +b);
    return new Date(n[0], n[1] - 1, n[2]);
}

export const ISOToDateTime = (s: string) => {
    if (!s) return null;
    const d = s.split("T");
    if (d.length == 0) return null;
    const dateBits = d[0].split("-");
    if (dateBits.length < 3) return null;
    const dateParts = dateBits.map(b => +b);

    if (d.length < 1) return null;
    const timeBits = d[1].split(":");
    if (timeBits.length < 3) return null;
    const timeParts = timeBits.map(b => +(b.replace("Z", "")));

    // note: ignoring milliseconds
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2], 0);
}

//export const removeTimeFromISODate = (isoDate: string) => dateToISO(moment(isoDate).startOf('day').toDate());
export const removeTimeFromISODate = (isoDate: string) => {
    if (!isoDate) return null;
    const d = isoDate.split("T");
    if (d.length == 0) return null;
    return d[0] + "T00:00:00.000Z";

}

