import * as React from 'react';
import { ActionButton } from './Buttons';
import { copyStringToClipboard } from '../clipboardUtils';
import { SystemMessage } from '../../system/systemMessage';
import { removeReturnToNavArguments } from '../../tourtracks/components/ReturnToNavLinks';
import { removeParameterFromUrl } from '../urlUtils';

export interface CopyURLToCliboardButtonProps {
}


const removeNotNeededArgs = (url: string) => {
    url = removeReturnToNavArguments(url);
    url = removeParameterFromUrl(url, "type");
    url = removeParameterFromUrl(url, "path");

    return url;
}


export const ShareButton = (props: {
    onClick: () => void,
    disabled?: boolean,
}) =>
    <ActionButton
        secondary
        label="Share"
        mini
        onClick={props.onClick}
        disabled={props.disabled}
    />

export const CopyURLToCliboardButton = (props: CopyURLToCliboardButtonProps) =>
    <ShareButton
        onClick={() => {
            const url = removeNotNeededArgs(window.location.href);
            copyStringToClipboard(url);
            SystemMessage.showInfo("Link copied to clipboard");
        }}
    />