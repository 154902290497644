import { ArtistModel, TourModel, ArtistSubscriptionStatusModel } from '../../api/services';
import { AppDataModel } from '../../api/services';

export enum LoadingStatus {
    NotLoaded = 0, Loading, Loaded
}

export interface TourTracksState {
    // redirectPath: string;
    loadingAppData: LoadingStatus;
    loadingArtist: LoadingStatus;
    loadingArtistId?: string;
    loadingTour: LoadingStatus;
    loadingTourId?: string;
    appData: AppDataModel;
    subscription: ArtistSubscriptionStatusModel;
    artist: ArtistModel;
    tour: TourModel;
}

// export interface ArtistsState {
//     // artistId?: string;
//     // artists: ArtistSummaryModel[];
//     data: ArtistModel;
//     loading: LoadingStatus;
//     // showTypes: string[];
//     // adminImageUrl?: string;
// }

// export interface ArtistState {
//     name: string;
//     id: string;
// }

// export interface ToursState {
//     // tourId?: string;
//     // tours: TourSummaryModel[];
//     data: TourModel;
//     loading: LoadingStatus;
// }
