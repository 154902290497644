import * as React from "react";
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { InviteAcceptStatus } from "../../api/services";
import { TTApi } from "../../api/ttApi";
import { GenericCard } from "../../common/components/Cards";
import { TTLogo } from "../Header";
import { useState, useEffect, useLayoutEffect } from 'react';
import { LinkNormal } from '../../common/components/Buttons';


//export class AcceptArtistUserInvite extends React.Component<RouteComponentProps<{ token: string }>, { acceptResult: InviteAcceptStatus, loading: boolean }> {
export const AcceptArtistUserInvite = (props: RouteComponentProps<{ token: string }>) => {

    const [acceptResult, setAcceptResult] = useState(undefined as InviteAcceptStatus);
    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        acceptInvite();
    }, [])

    const acceptInvite = async () => {
        const { token } = props.match.params;

        const r = await TTApi.users().acceptArtistUserInvite(token);

        setLoading(false);
        setAcceptResult(r);
    }

    if (acceptResult == InviteAcceptStatus.SUCCESS) return <Redirect to="/" />

    return <div className="readable-content">

        <TTLogo />
        <GenericCard>
            {loading && <div>
                <h2>Accepting artist invitation, please wait...</h2>
                <p className="subtle-text">Please <LinkNormal onClick={acceptInvite}>click here</LinkNormal> if this page doesn't redirect after a few seconds</p>
            </div>}
            {!loading && <div>
                {(acceptResult == InviteAcceptStatus.ALREADYACCEPTED) && <h2>Sorry, this invitation has already been accepted.</h2>}
                {(acceptResult == InviteAcceptStatus.EXPIRED) && <h2>Sorry, this invitation has expired.</h2>}
                {(acceptResult == InviteAcceptStatus.NOTFOUND) && <h2>Sorry, we can't locate this invite.</h2>}
                {!!acceptResult && <p>Please contact the artist manager to get your invite re-sent</p>}
            </div>}

        </GenericCard>
    </div>
}