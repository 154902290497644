import * as React from 'react';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ArtistModel, ShareInfoModel, SharingAccessEnum, TourModel } from '../../api/services';
import { TTApi } from '../../api/ttApi';
import { GenericCard } from '../../common/components/Cards';
import { WaitLoaderFullScreen } from '../../common/components/Loader';
import { Header } from '../Header';
import { getHoursToFriendlyTimeDisplay } from '../helpers/displayHelpers';
import { TTPaths } from '../navigation/paths';
import "./publicShareScreens.less";

const SharedTourItineraryReport = Loadable({ loader: () => import("./content/TourItineraryReport"), loading: WaitLoaderFullScreen });
const SharedTourBudgetReport = Loadable({ loader: () => import("./content/TourBudgetReport"), loading: WaitLoaderFullScreen });
const SharedTourFinancialsReport = Loadable({ loader: () => import("./content/TourFinancialsReport"), loading: WaitLoaderFullScreen });
const SharedTourTransactionsReport = Loadable({ loader: () => import("./content/TourTransactionsReport"), loading: WaitLoaderFullScreen });
const SharedTourCommissionsReport = Loadable({ loader: () => import("./content/TourCommimssionsReport"), loading: WaitLoaderFullScreen });

export const NoAccessMessage = () =>
    <div>
        <p>Sorry, we cannot display this content. <br />This link has either expired or is incorrect.</p>
    </div>


export interface SharedContentProps {
    token: string,
    artist: ArtistModel,
    tour: TourModel,
}

export const PublicShareScreens = () => {

    const match = useRouteMatch<{ token: string, access: string }>();

    const token = match.params.token;
    const access = Number.parseInt(match.params.access) as SharingAccessEnum;

    const [share, setShare] = useState(undefined as ShareInfoModel);
    const [noData, setNoData] = useState(false);

    const load = async () => {
        const d = await TTApi.publicShare().getShareInfo(token, access);
        if (!d) {
            setNoData(true);
        }
        else {
            setShare(d);
        }
    }

    useEffect(() => { load() }, [])

    if (!share && !noData) return <WaitLoaderFullScreen />

    const contentProps: SharedContentProps = {
        token,
        artist: !!share ? share.artist : undefined,
        tour: !!share ? share.tour : undefined,
    }

    const Heading = (props: { heading: string }) =>
        <>
            {!!share &&
                <h1 className="artist-tour-info show-small-screen shared-heading">
                    <span className="artist-name">{share.artist.name}</span>
                    <br />
                    <span className="tour-name">{share.tour.name}</span>
                    <br />

                </h1>
            }
            <h1 className="shared-heading">
                {props.heading}
                {!!share && !!share.expiresHours &&
                    <div className="expire-text">&nbsp;Expires in {getHoursToFriendlyTimeDisplay(share.expiresHours)}</div>
                }
            </h1>
        </>

    return <div className="public-share-screens">
        <ToastContainer />
        <header>
            <Header
                artist={contentProps.artist}
                tour={contentProps.tour}
            />
        </header>
        <div className="body-stuff">
            {noData &&
                <GenericCard className="expired-message">
                    <NoAccessMessage />
                </GenericCard>
            }
            {!!share &&
                <Switch>
                    <Route path={TTPaths.publicShareContent(token, SharingAccessEnum.TourItineraryReport)}>
                        <>
                            <Heading heading="Itinerary Report" />
                            <SharedTourItineraryReport {...contentProps} />
                        </>
                    </Route>
                    <Route path={TTPaths.publicShareContent(token, SharingAccessEnum.TourBudgetReport)}>
                        <>
                            <Heading heading="Budget Report" />
                            <SharedTourBudgetReport {...contentProps} />
                        </>
                    </Route>
                    <Route path={TTPaths.publicShareContent(token, SharingAccessEnum.TourFinancialsReport)}>
                        <>
                            <Heading heading="Tour Report" />
                            <SharedTourFinancialsReport {...contentProps} />
                        </>
                    </Route>
                    <Route path={TTPaths.publicShareContent(token, SharingAccessEnum.TourTransactionsReport)}>
                        <>
                            <Heading heading="Transactions Report" />
                            <SharedTourTransactionsReport {...contentProps} />
                        </>
                    </Route>
                    <Route path={TTPaths.publicShareContent(token, SharingAccessEnum.TourCommissionsReport)}>
                        <>
                            <Heading heading="Commission Report" />
                            <SharedTourCommissionsReport {...contentProps} />
                        </>
                    </Route>
                </Switch>
            }
        </div>
    </div>
}
