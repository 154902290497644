import * as React from "react";
import { useEffect } from "react";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import { ArtistSubscriptionAccessEnum } from "../../api/services";
import { ActionButton } from "../../common/components/Buttons";
import { GenericCard } from '../../common/components/Cards';
import { WaitLoaderFullScreen } from '../../common/components/Loader';
import { PopupBox } from '../../common/components/PopupBox';
import { runAddressDecodeTests } from "../helpers/locationHelpers";
import { isManagerRole } from "../helpers/userUtils";
import { LoadingStatus } from "../state/state";
import { NonAdminSubscriptionBlock } from "../subscriptions/NonAdminSubscriptionBlock";
import { TourTracksProps } from '../TourTracksMainScreen';
import "./mainNavigation.less";
import { TTPaths } from './paths';

const ModuleLoadErrorMessage = () =>
  <PopupBox >
    <GenericCard>
      <div className="moduleloaderror-popup">
        <h1>TourTracks has been updated</h1>
        Please reload to get the latest version
        <br /><br />
        <br /><br />
        <ActionButton label="Reload" onClick={() => window.location.reload(true)} />
      </div>
    </GenericCard>
  </PopupBox>

export const ModuleLoadingIndicator = (props) => {
  if (props.error) {
    return ModuleLoadErrorMessage;
  } else if (props.pastDelay) {
    return <WaitLoaderFullScreen />;
  } else {
    return null;
  }

}

// ****************************
// NOTE: for new ones of these to work... the control to retrun needs to be the default export!
// ****************************

const ArtistHome = Loadable({ loader: () => import("../artist/ArtistHome"), loading: ModuleLoadingIndicator });
const NewArtistFormPopup = Loadable({ loader: () => import("../artist/NewArtistForm"), loading: WaitLoaderFullScreen });
const ArtistSetupWizard = Loadable({ loader: () => import("../artist/setupWizard/ArtistSetupWizard"), loading: ModuleLoadingIndicator });
const ArtistSettings = Loadable({ loader: () => import("../artist/ArtistSettings"), loading: ModuleLoadingIndicator });
const ManageUsersScreen = Loadable({ loader: () => import("../users/ManageUsersScreen"), loading: WaitLoaderFullScreen });
const ArtistSubscription = Loadable({ loader: () => import("../subscriptions/ArtistSubscription"), loading: WaitLoaderFullScreen });
const ArtistCustomSubcategories = Loadable({ loader: () => import("../artist/CustomSubcategories"), loading: WaitLoaderFullScreen });
const EditSelectedArtistCard = Loadable({ loader: () => import("../artist/EditArtist"), loading: WaitLoaderFullScreen });
const ItineraryTool = Loadable({ loader: () => import("../budgeting/ItineraryTool"), loading: WaitLoaderFullScreen });
const BudgetTool = Loadable({ loader: () => import("../budgeting/BudgetingTool"), loading: WaitLoaderFullScreen });
const TourFinancialsWizardView = Loadable({ loader: () => import("../financials/TourFinancialsWizard"), loading: WaitLoaderFullScreen });
const BudgetGridReport = Loadable({ loader: () => import("../reports/budgetGridReport/BudgetGridReport"), loading: WaitLoaderFullScreen });
const TourMegaItineraryReport = Loadable({ loader: () => import("../reports/tourMegaReport/itinerary/TourMegaItineraryReportRouted"), loading: WaitLoaderFullScreen });
const TourMegaFinancialsReport = Loadable({ loader: () => import("../reports/tourMegaReport/financials/TourFinancialsReportRouted"), loading: WaitLoaderFullScreen });
const TourMegaBudgetReport = Loadable({ loader: () => import("../reports/tourMegaReport/budget/TourMegaBudgetReportRouted"), loading: WaitLoaderFullScreen });
const TourMegaTransactionsReport = Loadable({ loader: () => import("../reports/tourMegaReport/transactions/TourMegaTransactionsReportRouted"), loading: WaitLoaderFullScreen });
const TourActualsCommissionsReport = Loadable({ loader: () => import("../reports/tourMegaReport/commissionsReport/TourActualsCommissionsReportRouted"), loading: WaitLoaderFullScreen });
const ArtistTourShowsReport = Loadable({ loader: () => import("../reports/artistTourShowsReport/ArtistTourShowsReport"), loading: WaitLoaderFullScreen });
const EditSelectedTourCard = Loadable({ loader: () => import("../tour/EditTour"), loading: WaitLoaderFullScreen });
const HomeScreen = Loadable({ loader: () => import("./HomeScreen"), loading: WaitLoaderFullScreen });
const TourHome = Loadable({ loader: () => import("../tour/TourHome"), loading: WaitLoaderFullScreen });
const ArtistReportDashboard = Loadable({ loader: () => import("../reports/artistReportDashboard/ArtistReportDashboard"), loading: WaitLoaderFullScreen });
const NewTourFormCard = Loadable({ loader: () => import("../tour/NewTourForm"), loading: WaitLoaderFullScreen });
const SuperAdminScreen = Loadable({ loader: () => import("../superAdmin/SuperAdminScreen"), loading: WaitLoaderFullScreen });

export const MainNavigation = (props: TourTracksProps) => {

  useEffect(() => {
    // run some tests if we are local
    if (window.location.href.startsWith("http://localhost:")) {
      runAddressDecodeTests();
    }

  }, []);

  const { tourTracks } = props;
  const { state, actions } = tourTracks;
  const { appData, artist, tour, subscription } = state;

  const isManager = !!appData && !!artist && isManagerRole(appData.artistRoles, artist.id)

  // once we have the subscription info we should block any non-admins from using
  // anything if they shouldn't
  const blockNoMultiUserSubscription = !!subscription && !subscription.access.find(a => a == ArtistSubscriptionAccessEnum.MultiUser);
  if (blockNoMultiUserSubscription && !isManager && state.loadingAppData == LoadingStatus.Loaded) return <NonAdminSubscriptionBlock artistId={artist.id} />;

  if (!!state && state.loadingAppData != LoadingStatus.Loaded) return <WaitLoaderFullScreen />

  return (
    <div>
      <Switch>
        {/* artist list */}
        <Route exact path="/">
          <HomeScreen />
        </Route>

        <Route path={TTPaths.superAdmin()}>
          <SuperAdminScreen />
        </Route>

        {/* new artist form */}
        <Route exact path={TTPaths.newArtist()}>
          <>
            <div className="hide-small-screen">
              <HomeScreen />
            </div>
            <NewArtistFormPopup {...props} backTo="/" />
          </>
        </Route>

        {/* artist home */}
        <Route exact path={TTPaths.artistHome()}>
          {!!artist && <ArtistHome artist={artist} appData={appData} />}
        </Route>

        {/* artist setup wizard (with artist home in the background) */}
        <Route path={TTPaths.artistSetupHome()}>
          {!!artist && !!appData && !!subscription &&
            <>
              <ArtistHome artist={artist} appData={appData} hideHelpHead />
              <ArtistSetupWizard
                artist={artist}
                appData={appData}
                access={subscription.access}
              />
            </>
          }
        </Route>

        {/* custom subcategories */}
        <Route exact path={TTPaths.artistSubcategories()}>
          {!!artist && !!appData && !!subscription &&
            <ArtistCustomSubcategories
              artist={artist}
              access={subscription.access}
              categories={appData.categories}
            />
          }
        </Route>

        {/* users */}
        <Route exact path={TTPaths.artistUsers()}>
          {!!artist && !!subscription &&
            <ManageUsersScreen
              artistId={artist.id}
              access={subscription.access}
            />
          }
        </Route>

        {/* artist subscription */}
        <Route exact path={TTPaths.artistSubscription()}>
          {!!artist && !!appData && (
            <ArtistSubscription
              artistId={artist.id}
              artistCurrency={artist.currencyCode}
              // make sure we have a fresh version of the subscription whenever something changes with that
              onSubscriptionChange={async () => {
                await actions.refreshSubscriptionInfo()
              }}
            />
          )}
        </Route>

        {/* artist settings */}
        <Route path={TTPaths.artistSettings()}>
          <>
            {!!artist && <ArtistSettings {...props} />}
            {/* popups for artist settings page */}
            <Switch>
              {/* edit artist popup */}
              <Route exact path={TTPaths.artistEdit()}>
                {!!artist &&
                  <EditSelectedArtistCard
                    {...props}
                    goTo={TTPaths.artistSettings(artist.id)}
                    backTo={TTPaths.artistSettings(artist.id)}
                  />
                }
              </Route>
            </Switch>

          </>
        </Route>


        {/* this isn't rigt... best to nest this route under the artist page at some point */}
        <Route exact path={TTPaths.newTour()}>
          {!!artist &&
            <>
              <ArtistHome artist={artist} appData={appData} hideHelpHead />
              <PopupBox>
                <NewTourFormCard
                  {...props}
                  artistCurrencyCode={artist.currencyCode}
                  backTo={`/${artist.id}`}
                />
              </PopupBox>
            </>
          }
        </Route>


        <Route exact path={TTPaths.reportArtistReport()}>
          {!!artist && !!subscription &&
            <ArtistReportDashboard
              artist={artist}
              access={subscription.access}
            />
          }
        </Route>


        <Route exact path={TTPaths.reportArtistShowSearch()}>
          {!!artist && !!subscription &&
            <ArtistTourShowsReport
              artist={artist}
              access={subscription.access}
            />
          }
        </Route>



        {/* tour home page */}
        <Route exact path={TTPaths.tourHome()}>
          {!!artist && !!tour && !!subscription &&
            <TourHome
              tour={tour}
              artist={artist}
              appData={appData}
              subscription={subscription}
            />
          }
        </Route>

        {/* edit tour */}
        <Route exact path={TTPaths.tourEdit()}>
          {!!artist && !!tour && !!subscription &&
            <>
              <TourHome
                tour={tour}
                artist={artist}
                appData={appData}
                subscription={subscription}
              />
              <PopupBox>
                <EditSelectedTourCard
                  {...props}
                  goTo={TTPaths.tourHome(artist.id, tour.id)}
                  backTo={TTPaths.tourHome(artist.id, tour.id)}
                />
              </PopupBox>
            </>
          }
        </Route>

        <Route path={TTPaths.reportsTourBudgetGridReport()}>
          {!!artist && !!tour &&
            <BudgetGridReport
              artist={artist}
              tour={tour}
              currencies={appData.currencies}
            />
          }
        </Route>

        <Route path={TTPaths.reportsTourMegaItineraryReport()}>
          {!!artist && !!tour && !!subscription &&
            <TourMegaItineraryReport
              artist={artist}
              tour={tour}
              access={subscription.access}
            />
          }
        </Route>

        <Route path={TTPaths.reportsTourMegaBudgetReport()}>
          {!!artist && !!tour && !!subscription &&
            <TourMegaBudgetReport
              artist={artist}
              tour={tour}
              access={subscription.access}
            />
          }
        </Route>

        <Route path={TTPaths.reportsTourMegaReport()}>
          {!!artist && !!tour && !!subscription &&
            <TourMegaFinancialsReport
              artist={artist}
              tour={tour}
              access={subscription.access}
            />
          }
        </Route>

        <Route path={TTPaths.reportsTourMegaTransactionsReport()}>
          {!!artist && !!tour &&
            <TourMegaTransactionsReport
              artist={artist}
              tour={tour}
            />
          }
        </Route>

        <Route path={TTPaths.reportsTourCommissionsReport()}>
          {!!artist && !!tour &&
            <TourActualsCommissionsReport
              artist={artist}
              tour={tour}
            />
          }
        </Route>

        {/* itinerary tool */}
        <Route path={TTPaths.itineraryToolHome()}>
          {!!artist && !!tour && !!appData && !!subscription &&
            <ItineraryTool
              artist={artist}
              tour={tour}
              appData={appData}
              access={subscription.access}
            />
          }
        </Route>

        {/* budgeting tool */}
        <Route path={TTPaths.budgetingHome()}>
          {!!artist && !!tour && !!appData && !!subscription && (
            <BudgetTool
              artist={artist}
              tour={tour}
              appData={appData}
              access={subscription.access}
            />
          )}
        </Route>

        <Route path={TTPaths.tourActualsWizardStep("")}>
          {!!tour &&
            <TourFinancialsWizardView {...props} />
          }
        </Route>

      </Switch>


    </div >
  )
}
