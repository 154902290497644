
import { useReducer } from 'react';
import { AppDataModel, ArtistCreateModel } from '../../api/services';
import { TTApi } from '../../api/ttApi';
import { orderByCategory } from '../helpers/categoryHelpers';
import { initialTourTracksState, tourTracksReducer } from './reducers';
import { LoadingStatus, TourTracksState } from './state';


export const useTourTracks = (): TourTracksApp => {


    const [state, dispatch] = useReducer(tourTracksReducer, initialTourTracksState);

    const actions = {

        loadAppData: async () => {
            await dispatch({ type: "APPDATA/REQUESTED" });
            const appData = await TTApi.appData().get();

            // fix order of categories
            appData.categories = orderByCategory(appData.categories, c => c.name);

            await dispatch({ type: "APPDATA/RECEIVED", appData: appData });
        },

        refreshSubscriptionInfo: async () => {
            const a = state.artist;
            const s = await TTApi.subscriptions().getCurrentStatus(a.id);

            if (!!a) await dispatch({ type: "ARTIST/SELECTED", artist: a, subscription: s });
        },

        createArtist: async (model: ArtistCreateModel) => {

            const newId = await TTApi.artists().create(model);

            // we need to reload the roles since we have a new artist with a new role
            const r = await TTApi.appData().getArtistRoles();
            dispatch({
                type: "APPDATA/RECEIVED",
                appData: new AppDataModel({
                    ...state.appData,
                    artistRoles: r,
                })
            });

            return newId;
        },

        // force update will re-load the artist info even if the same artist is the current one
        selectArtist: async (artistId: string, forceUpdate?: boolean) => {

            if (!artistId) {
                if (!!state.artist) await dispatch({ type: "ARTIST/SELECTED", artist: undefined, subscription: undefined });
            }
            else {

                const artistAlreadySelected = !!artistId && !!state.artist && artistId == state.artist.id;
                const artistAlreadyLoading = state.loadingArtistId == artistId && state.loadingArtist == LoadingStatus.Loading;
                if ((artistAlreadyLoading || artistAlreadySelected) && !forceUpdate) return;

                await dispatch({ type: "ARTIST/REQUESTED", artistId });

                const a = await TTApi.artists().get(artistId);
                const s = await TTApi.subscriptions().getCurrentStatus(artistId);

                if (!!a) await dispatch({ type: "ARTIST/SELECTED", artist: a, subscription: s });
            }

        },

        selectTour: async (tourId: string, forceUpdate?: boolean) => {
            if (!tourId) {
                if (!!state.tour) await dispatch({ type: "TOUR/SELECTED", tour: undefined });
            }
            else {
                const tourAlreadySelected = !!tourId && !!state.tour && tourId == state.tour.id;
                const tourAlreadyLoading = state.loadingTourId == tourId && state.loadingTour == LoadingStatus.Loading;
                if ((tourAlreadyLoading || tourAlreadySelected) && !forceUpdate) return;

                await dispatch({ type: "TOUR/REQUESTED", tourId });
                const tour = await TTApi.tours().get(tourId);
                await dispatch({ type: "TOUR/SELECTED", tour: tour });
            }
        },
    }

    return {
        actions,
        state,

    }
}

// NOTE: this probably isn't the best way to do things
// need to review this whole thing

export type TourTracksActions = {
    loadAppData: () => Promise<void>;
    refreshSubscriptionInfo: () => Promise<void>;
    createArtist: (model: ArtistCreateModel) => Promise<string>;
    selectArtist: (artistId: string, forceUpdate?: boolean) => Promise<void>;
    selectTour: (tourId: string, forceUpdate?: boolean) => Promise<void>;
}

export type TourTracksApp = {
    state: TourTracksState,
    actions: TourTracksActions
}