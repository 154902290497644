import * as React from "react";
import { TourTracksProps } from '../TourTracksMainScreen';
import { Route, Switch } from 'react-router';
import { NavLink, HashRouter } from 'react-router-dom';
import { TTPaths } from './paths';
import "./breadcrumbs.less"

export const BreadCrumbs = (props: TourTracksProps) => {

    const { tourTracks } = props;
    const { state } = tourTracks;
    const { artist, tour, subscription } = state;

    return <div className="breadcrumbs no-print">
        {!!artist &&
            <>
                <Switch>
                    <Route path={TTPaths.artistHome()} >
                        <NavLink to="/" >Artists</NavLink>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.newArtist()} >
                        <span>&nbsp;&gt;&nbsp;New</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.artistHome() + "/:something"} >
                        <span>&nbsp;&gt;&nbsp;<NavLink to={TTPaths.artistHome(artist.id)} className="artist-name">{artist.name}</NavLink></span>
                    </Route>
                    <Route path={TTPaths.artistHome()} >
                        <span>&nbsp;&gt;&nbsp;{artist.name}</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.artistSubcategories()} >
                        <span>&nbsp;&gt;&nbsp;<NavLink to={TTPaths.artistSettings(artist.id)} className="artist-name">Settings</NavLink>&gt;&nbsp;&gt;&nbsp;Subcategories</span>
                    </Route>
                    <Route path={TTPaths.artistSettings() + "/:something"} >
                        <span>&nbsp;&gt;&nbsp;<NavLink to={TTPaths.artistSettings(artist.id)} className="artist-name">Settings</NavLink></span>
                    </Route>
                    <Route path={TTPaths.artistSettings()} >
                        <span>&nbsp;&gt;&nbsp;Settings</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.artistUsers()} >
                        <span>&nbsp;&gt;&nbsp;Users</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.budgetPresetsHome()} >
                        <span>&nbsp;&gt;&nbsp;Budget Pre-sets</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path={TTPaths.reportArtistReport()} >
                        <span>&nbsp;&gt;&nbsp;Reports</span>
                    </Route>
                </Switch>
                <Switch>
                    <Route path="/:artistId/newtour" >
                        <span>&nbsp;&gt;&nbsp;New</span>
                    </Route>
                </Switch>
                {!!tour &&
                    <>
                        <Switch>
                            <Route path={TTPaths.tourHome() + "/:something"} >
                                <span>
                                    &nbsp;&gt;&nbsp;<NavLink to={TTPaths.tourHome(artist.id, tour.id)} >{tour.name}</NavLink>
                                </span>
                            </Route>
                            <Route path={TTPaths.tourHome()} >
                                <span>
                                    &nbsp;&gt;&nbsp;{tour.name}
                                </span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.tourActualsWizardBase()} >
                                <span>&nbsp;&gt;&nbsp;Financials</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.reportsTourMegaReport()} >
                                <span>&nbsp;&gt;&nbsp;Tour Report</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.reportsTourMegaItineraryReport()} >
                                <span>&nbsp;&gt;&nbsp;Itinerary Report</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.reportsTourMegaBudgetReport()} >
                                <span>&nbsp;&gt;&nbsp;Budget Report</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.reportsTourCommissionsReport()} >
                                <span>&nbsp;&gt;&nbsp;Commissions Report</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.reportsTourMegaTransactionsReport()} >
                                <span>&nbsp;&gt;&nbsp;Transactions Report</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.itineraryToolHome()} >
                                <span>&nbsp;&gt;&nbsp;Itinerary</span>
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path={TTPaths.budgetingHome()} >
                                <span>&nbsp;&gt;&nbsp;Budget</span>
                            </Route>
                        </Switch>
                    </>
                }
            </>
        }
    </div>
}