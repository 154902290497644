import { createBrowserHistory } from 'history';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { TTApi } from './api/ttApi';
import { Auth0Callback } from './auth/Auth0Callback';
import Auth0Login from './auth/Auth0Login';
import Auth0Signup from './auth/Auth0Signup';
import { globalConfig } from './config/globalConfig';
import './css/site.less';
import Examples from './testing/Examples';
import { QuickBooksCallback } from './tourtracks/financials/quickbooks/QuickBooksCallback';
import { TTTracking } from './tourtracks/helpers/eventTracking';
import { TTPaths } from './tourtracks/navigation/paths';
import { PublicShareScreens } from './tourtracks/publicShare/PublicShareScreens';
import { useTourTracks } from './tourtracks/state/hooks';
import TourTracksMainScreen from './tourtracks/TourTracksMainScreen';
import { AcceptArtistUserInvite } from './tourtracks/users/AcceptArtistUserInvite';

const root = document.getElementById('root');

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const LoadingScreen = () =>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh" }}>
        <img style={{ margin: "auto", width: "50%" }} src="https://tourtracks.blob.core.windows.net/assets/TT-Icon.svg" alt="TourTracks is loading ..." />
    </div>


const Scripts = () => {

    // debugger;
    const loadMapsScript = () => {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${globalConfig.google.apiKey}&libraries=places,geometry`;
        document.body.appendChild(script);
    }

    useEffect(() => {
        loadMapsScript();
    }, []);

    return <>
        {/* google places api */}
        {/* <script type="text/javascript" src={`https://maps.googleapis.com/maps/api/js?key=${globalConfig.google.apiKey}&libraries=places,geometry`}></script> */}
        {/* <span>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAXXXXXXXXXXXXX</span> */}
    </>
}


const App = () => {

    const [loaded, setLoaded] = useState(false);

    const tourTracks = useTourTracks();

    const loadConfig = async () => {

        try {
            const config = await TTApi.system().getConfig();

            // note: all this is a legacy hack.. will need to
            // fix this up (ie make not global) at some point soon
            globalConfig.auth0 = {
                domain: config.auth0Domain,
                clientId: config.auth0ClientId,
                redirectUri: config.auth0RedirectUri,
                audience: config.auth0Audience,
            };

            globalConfig.stripe = {
                publicKey: config.stripePublicKey,
            }

            globalConfig.google = {
                apiKey: config.googleKey,
                analyticsId: config.googleAnalyticsId,
            }

            const tracking = TTTracking(config);

            tracking.start();

            // track page changes
            tracking.trackCurrentPath();
            history.listen((location, action) => {
                tracking.trackCurrentPath();
            })

            // send current user info to full story
            tracking.trackUser();
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    // first thing we do is load some config
    React.useEffect(() => { loadConfig() }, []);

    if (!loaded) return <LoadingScreen />

    return <>
        <Scripts />
        <BrowserRouter>
            <div className="content-container">
                <Switch>
                    <Route path='/testing' component={Examples} />
                    <Route path='/callback/' component={Auth0Callback} />
                    <Route path='/login/' component={Auth0Login} />
                    <Route path='/signup/' component={Auth0Signup} />

                    <Route path='/quickbookscallback/' component={QuickBooksCallback} />

                    <Route path={TTPaths.acceptArtistInvite()} component={AcceptArtistUserInvite} />
                    <Route path={TTPaths.publicShareContent()} component={PublicShareScreens} />
                    <Route path='/:artistId?/tours/:tourId?'>
                        <TourTracksMainScreen tourTracks={tourTracks} />
                    </Route>
                    <Route path='/:artistId?' >
                        <TourTracksMainScreen tourTracks={tourTracks} />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    </>
}

function renderApp() {
    if (root == null) throw "Where's my app??";

    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(<App />, root);
}

renderApp();

