
// import * as H from 'history';


export const argsToObject = (searchArgs: string): any => {
    if (!searchArgs) return {};
    var queryString = searchArgs.split('?')[1];
    var obj = {};
    if (queryString) {
        queryString = queryString.split('#')[0];
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split('=');
            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function (v) {
                paramNum = v.slice(1, -1);
                return '';
            });

            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                if (typeof paramNum === 'undefined') {
                    obj[paramName].push(paramValue);
                }
                else {
                    obj[paramName][paramNum] = paramValue;
                }
            }
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;

}

export const getUrlArgs = (): any => {
    var url = window.location.href;

    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    var obj = {};
    if (queryString) {
        queryString = queryString.split('#')[0];
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split('=');
            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function (v) {
                paramNum = v.slice(1, -1);
                return '';
            });

            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                if (typeof paramNum === 'undefined') {
                    obj[paramName].push(paramValue);
                }
                else {
                    obj[paramName][paramNum] = paramValue;
                }
            }
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
}

export const objectToUrlArgs = (obj: any) => Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => key + '=' + obj[key])
    .join('&');

// export const getAsUrlArgs = (obj: any, location: H.History) => {
//     const args = objectToUrlArgs(obj);
//     // const args2 = !!args ? '?' + args : "";
//     // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + args2;
//     return args;
// }

// export const setUrlArgs = (obj: any, location: H.History) => {
//     const args = objectToUrlArgs(obj);
//     const args2 = !!args ? '?' + args : "";
//     var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + args2;
//     location.push({ search: args });
// }

export const getCurrentUrlOrigin = () => {
    return (window.location.origin)
        ? window.location.origin
        : window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
}

export const removeParameterFromUrl = (url, parameter) => {

    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;

}