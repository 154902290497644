import * as React from 'react';
import "./publicShareButton.less";
import { SharingAccessEnum, PublicShareCreateModel, PublicShareCreateResponseModel, ArtistSubscriptionStatusModel, ArtistSubscriptionAccessEnum } from '../../api/services';
import { ActionButton, LinkNormal, CloseSubmit, CloseSave } from '../../common/components/Buttons';
import { ShareButton } from '../../common/components/ReportShareButton';
import { useState, useRef } from 'react';
import { TTApi } from '../../api/ttApi';
import { PopupMenu } from '../../common/components/PopupMenu';
import { WaitLoader } from '../../common/components/Loader';
import { TTPaths } from '../navigation/paths';
import { getHoursToFriendlyTimeDisplay as getFriendlyTimeDisplayFromHours } from '../helpers/displayHelpers';
import { getCurrentUrlOrigin } from '../../common/urlUtils';
import { SystemMessage } from '../../system/systemMessage';
import { copyStringToClipboard } from '../../common/clipboardUtils';

export interface PublicShareButtonProps {
    artistId: string;
    tourId?: string;
    access: SharingAccessEnum;
}

export const PublicShareButton = (props: PublicShareButtonProps) => {


    const { } = props;

    const [shareInfo, setShareInfo] = useState(undefined as PublicShareCreateResponseModel);
    const [waiting, setWaiting] = useState(false);
    const linkBoxRef = useRef(null);

    const loadShare = async () => {
        setWaiting(true);

        const s = await TTApi.publicShare().createShare(new PublicShareCreateModel({
            artistId: props.artistId,
            tourId: props.tourId,
            access: props.access
        }));

        setShareInfo(s);

        setWaiting(false);
    }

    const getShareUrl = (s: PublicShareCreateResponseModel) =>
        getCurrentUrlOrigin() + TTPaths.publicShareContent(shareInfo.token, props.access);

    return <div className="public-share-button">
        <ShareButton
            disabled={waiting}
            onClick={() => loadShare()}
        />
        {waiting &&
            <PopupMenu>
                <WaitLoader size={40} />
            </PopupMenu>
        }
        {!!shareInfo &&
            <PopupMenu>
                <h1>Share link created</h1>
                <p>Anyone with this link will be able to view the report for {getFriendlyTimeDisplayFromHours(shareInfo.hours)}</p>
                <p>
                    Copy and paste this link:<br />
                    <input
                        className="share-link"
                        value={getShareUrl(shareInfo)}
                        onClick={() => linkBoxRef.current.setSelectionRange(0, linkBoxRef.current.value.length)}
                        ref={linkBoxRef}
                        readOnly
                    />
                </p>
                <CloseSave
                    onClose={() => {
                        const url = getShareUrl(shareInfo);
                        copyStringToClipboard(url);
                        SystemMessage.showInfo("Link copied to clipboard");
                        setShareInfo(undefined);
                    }}
                    onSave={() => setShareInfo(undefined)}
                    closeText="Copy Link"
                    saveText="Done"
                />
            </PopupMenu>
        }
    </div>

}


export const LinkPublicShareButton = (props: {
    subscription: ArtistSubscriptionStatusModel;
    artistId: string;
    tourId?: string;
    access: SharingAccessEnum;
}) =>
    <>
        {!!props.subscription && props.subscription.access.find(s => s == ArtistSubscriptionAccessEnum.MultiUser) &&
            <span className="no-print">&nbsp;&nbsp;&nbsp;
            <PublicShareButton
                    artistId={props.artistId}
                    tourId={props.tourId}
                    access={props.access}
                />
            </span>
        }
    </>