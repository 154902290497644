import * as React from "react";

export interface OpenerProps {
    defaultComponent: any;
    openComponent?: any;
    children: any;
    keepOpen?: boolean;
}

export class Opener extends React.Component<OpenerProps, { open: boolean }> {

    constructor(props: OpenerProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleOpen = () => {

        this.setState({ open: true });
        if (!this.props.keepOpen) document.addEventListener('click', this.doClose);

    }

    handleClose = () => {
        this.doClose();
    }

    doClose = () => {
        // document.removeEventListener('click', this.handleClose);
        if (!this.props.keepOpen) document.removeEventListener('click', this.doClose);
        this.setState({ open: false });
    }


    render() {

        const { open } = this.state;
        const { openComponent, defaultComponent } = this.props;

        return <span>
            {!open && <span style={{ cursor: "pointer" }} onClick={this.handleOpen}>{openComponent || defaultComponent}</span>}
            {open &&
                <span>
                    <span style={{ cursor: "pointer" }} onClick={this.handleClose}>
                        {defaultComponent}
                    </span>
                    {this.props.children}
                </span>
            }
        </span>
    }

}