import { ArtistUserRole } from '../../api/services';

export const getRoleDescription = (r: ArtistUserRole) => {
    switch (r) {
        case ArtistUserRole.MANAGER: return "Admin";
        case ArtistUserRole.BUDGETMANAGER: return "Budgets Only";
        case ArtistUserRole.BOOKINGAGENT: return "Itinerary Only";
        //case ArtistUserRole.BOOKINGAGENT: return "Booking Agent";
        case ArtistUserRole.ARTISTMANAGER: return "Itinerary + Budget + Financials";
        //case ArtistUserRole.ARTISTMANAGER: return "Artist Manager";
        case ArtistUserRole.FINANACIALSMANAGER: return "Financials Only";
        case ArtistUserRole.REPORTVIEWER: return "View Only";
        default: throw "Missing role description";
    }
}


export const getRoleOrder = (r: ArtistUserRole) => {
    switch (r) {
        case ArtistUserRole.MANAGER: return 0;
        case ArtistUserRole.BUDGETMANAGER: return 3;
        case ArtistUserRole.BOOKINGAGENT: return 2;
        case ArtistUserRole.ARTISTMANAGER: return 1;
        case ArtistUserRole.FINANACIALSMANAGER: return 4;
        case ArtistUserRole.REPORTVIEWER: return 5;
        default: 100;
    }
}


// until we find a nicer way of looping through enums in typescript
export const allRoles = [ArtistUserRole.REPORTVIEWER, ArtistUserRole.ARTISTMANAGER, ArtistUserRole.BUDGETMANAGER, ArtistUserRole.BOOKINGAGENT, ArtistUserRole.FINANACIALSMANAGER, ArtistUserRole.MANAGER];

export const isManagerRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    artistRoles[artistId] == ArtistUserRole.MANAGER

export const isArtistManagerRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.ARTISTMANAGER].indexOf(artistRoles[artistId]) != -1;

export const isTourCreaterRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.ARTISTMANAGER, ArtistUserRole.BOOKINGAGENT].indexOf(artistRoles[artistId]) != -1;

export const isActualsReportsViewRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.ARTISTMANAGER, ArtistUserRole.FINANACIALSMANAGER, ArtistUserRole.REPORTVIEWER].indexOf(artistRoles[artistId]) != -1;

export const isBudgetAdminRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.ARTISTMANAGER, ArtistUserRole.BUDGETMANAGER].indexOf(artistRoles[artistId]) != -1;

export const isItineraryAdminRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.ARTISTMANAGER, ArtistUserRole.BUDGETMANAGER, ArtistUserRole.BOOKINGAGENT].indexOf(artistRoles[artistId]) != -1;

export const isFinancialsAdminRole = (artistRoles: { [key: string]: ArtistUserRole; }, artistId: string) =>
    [ArtistUserRole.MANAGER, ArtistUserRole.FINANACIALSMANAGER].indexOf(artistRoles[artistId]) != -1;

