import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Auth from './authService';

type Props = RouteComponentProps<{}>;

class Auth0Signup extends React.Component<Props, {}> {

    public componentDidMount() {
        var auth = new Auth();
        auth.login("signUp");
    }

    public render() {
        return <div className="readable-content">
            <h2>Redirecting to the signup page... </h2>
        </div>;
    }
}

export default Auth0Signup;