import * as moment from 'moment';
import * as React from "react";
import { QuickBooksAuthResponseModel, TransactionImportModel } from "../../../api/services";
import { TTApi } from '../../../api/ttApi';
import { SystemMessage } from '../../../system/systemMessage';

export const useQuickbooksLogin = () => {

    const [url, setUrl] = React.useState(undefined as string);

    const loadUrl = async () => {
        QuickBooksAPI.setReturnUrl(document.location.pathname + document.location.hash);
        const url = await TTApi.quickbooks().getAuthUrl()
        setUrl(url);
    }

    React.useEffect(() => { loadUrl() }, [])

    return url;

}


export const useQuickbooksGetAuthToken = (code: string, realmId: string): [boolean, QuickBooksAuthResponseModel] => {

    const [loading, setLoading] = React.useState(true);
    const [result, setResult] = React.useState(undefined as QuickBooksAuthResponseModel);

    const getToken = async () => {
        try {
            const r = await TTApi.quickbooks().getAuthTokens(code, realmId);
            setResult(r);
        }
        catch (e) {
            SystemMessage.showError("Problem connecting to QuickBooks");
        }
        setLoading(false);
    }

    React.useEffect(() => { getToken() }, [])

    return [loading, result];

}
export const useQuickbooksTransactionsImporter = (realmId: string, authToken: string, tourCurrencyCode: string): [boolean, TransactionImportModel[]] => {

    const [loading, setLoading] = React.useState(true);
    const [result, setResult] = React.useState(undefined as TransactionImportModel[]);

    const getTransactionData = async () => {
        try {
            const r = await TTApi.quickbooks().getTransactionData(realmId, authToken, tourCurrencyCode);
            setResult(r);
        }
        catch (e) {
            SystemMessage.showError("Problem connecting to QuickBooks");
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (!!realmId && !!authToken) {
            getTransactionData();
        }
        else {
            setResult(undefined);
        }
    }, [realmId, authToken]);

    return [loading, result];

}

const RETURNURL_KEY = "quickbooks_returnurl";
const CODE_KEY = "quickbooks_code";
const REALMID_KEY = "quickbooks_realmid";
const AUTHCODEDATE_KEY = "quickbooks_storeauthcodedate";
const AUTHTOKEN_KEY = "quickbooks_authtoken";
const AUTHTOKENDATE_KEY = "quickbooks_authtokendate";

export const QuickBooksAPI = {

    clearAll: () => {
        localStorage.setItem(RETURNURL_KEY, "");
        localStorage.setItem(CODE_KEY, "");
        localStorage.setItem(REALMID_KEY, "");
        localStorage.setItem(AUTHCODEDATE_KEY, "");
        localStorage.setItem(AUTHTOKEN_KEY, "");
        localStorage.setItem(AUTHTOKENDATE_KEY, "");
    },

    setReturnUrl: (url: string) => localStorage.setItem(RETURNURL_KEY, url),
    getReturnUrl: () => localStorage.getItem(RETURNURL_KEY),
    clearReturnUrl: () => localStorage.removeItem(RETURNURL_KEY),

    setCodeAndRealm: (code: string, realmId: string) => {
        localStorage.setItem(CODE_KEY, code);
        localStorage.setItem(REALMID_KEY, realmId);
        const d = new Date();
        localStorage.setItem(AUTHCODEDATE_KEY, d.toString());
    },
    getRealmId: () => localStorage.getItem(REALMID_KEY),
    getAuthCode: () => {
        // NOTE: the auth code expires in 5 minutes.. so if it's older than that return nothing

        const storedDate = localStorage.getItem(AUTHCODEDATE_KEY);
        if (!storedDate) return undefined;

        const now = moment(new Date());
        const date = moment(new Date(storedDate));

        if (moment.duration(date.diff(now)).asSeconds() >= 5) return undefined;

        const code = localStorage.getItem(CODE_KEY);

        if (!code) return undefined;

        return code;
    },

    setAuthToken: (token: string, expiresSeconds: number) => {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + expiresSeconds);

        localStorage.setItem(AUTHTOKEN_KEY, token);
        localStorage.setItem(AUTHTOKENDATE_KEY, expires.toString());
    },
    getAuthToken: () => {
        const storedDate = localStorage.getItem(AUTHTOKENDATE_KEY);
        if (!storedDate) return undefined;

        const now = moment(new Date());
        const date = moment(new Date(storedDate));

        if (moment.duration(date.diff(now)).asSeconds() < 0) return undefined;

        return localStorage.getItem(AUTHTOKEN_KEY)
    },


}