import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Auth from './authService';
import { redirectToTempPath } from './urlRedirect';

type Props = RouteComponentProps<{}>;

export const Auth0Callback = (props: Props) => {

    const loginRedirect = () => {
        // trackUser();
        redirectToTempPath(props.history);
    }

    React.useEffect(() => {
        const auth = new Auth();
        auth.handleAuthentication()
            .then(r => loginRedirect())
            .catch(e => {
                // if we have an error, still try and redirect
                // if there's a problem then the user will be kicked back to the login screen
                console.log(e);
                // debugger;
                loginRedirect();
            });
    });

    return <div>
        <h1></h1>
    </div>
}