
const encodeForUrl = (s: string) => !!s ? encodeURIComponent(s) : s;
export const decodeFromUrl = (s: string) => !!s ? decodeURIComponent(s) : s;

export class TTPaths {

    static acceptArtistInvite = (token?: string) => "/accept/" + (token || ":token");

    static publicShareContent = (token?: string, access?: number) => "/shared/" + (token || ":token") + "/" + ((access != undefined) ? access.toString() : ":access");


    static newArtist = () => "/newartist";

    static artistHome = (aid?: string) => "/" + (aid || ":artistId");
    static artistSettings = (aid?: string) => TTPaths.artistHome(aid) + "/settings";

    static artistSetupHome = (aid?: string) => TTPaths.artistHome(aid) + "/setup";
    static artistSetup = (screen?: string, aid?: string) => TTPaths.artistSetupHome(aid) + "/" + (screen || ":screen");

    // note: these all go under artist settings heirachy wise
    static artistEdit = (aid?: string) => TTPaths.artistSettings(aid) + "/edit";
    static artistImageEdit = (aid?: string) => TTPaths.artistSettings(aid) + "/image";
    static artistUsers = (aid?: string) => TTPaths.artistSettings(aid) + "/users";
    static artistSubcategories = (aid?: string) => TTPaths.artistSettings(aid) + "/subcategories";
    static artistSubscription = (aid?: string) => TTPaths.artistHome(aid) + "/subscription";

    static budgetPresetsHome = (aid?: string) => TTPaths.artistSettings(aid) + "/presets";
    static budgetPresetsScreen = (categoryName: string, aid?: string) => TTPaths.budgetPresetsHome(aid) + "/" + categoryName.toLowerCase().replace(/ /g, '');


    static newTour = (aid?: string) => TTPaths.artistHome(aid) + "/newtour";


    static actualsWizardPathBase = "/financials";
    static actualsWizardFirstStepPath = "/itinerary";

    static tourHome = (aid?: string, tid?: string) => TTPaths.artistHome(aid) + "/tours/" + (tid || ":tourId");
    static tourActualsWizardBase = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + TTPaths.actualsWizardPathBase;
    static tourActualsWizardStart = (aid?: string, tid?: string) => TTPaths.tourActualsWizardBase(aid, tid) + TTPaths.actualsWizardFirstStepPath;
    static tourActualsWizardStep = (stepPath: string, aid?: string, tid?: string) => TTPaths.tourActualsWizardBase(aid, tid) + stepPath;
    static tourEdit = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/edit";
    // static tourNotes = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/notes";

    static reportArtistReport = (aid?: string) => TTPaths.artistHome(aid) + "/reports";
    static reportArtistShowSearch = (aid?: string) => TTPaths.artistHome(aid) + "/reports/shows";

    static reportsTourBudgetGridReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/budgetgridreport";

    static reportsTourMegaItineraryReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/itineraryreport";

    static reportsTourMegaBudgetReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/budgetreport";
    static reportsTourMegaTransactionsReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/transactionsreport";

    // static reportsTourCommissionsReport = (aid?: string, tid?: string, subcategory?: string) => TTPaths.tourHome(aid, tid) + "/commissionsreport/" + (encodeForUrl(subcategory) || ":subcategory"); 
    static reportsTourCommissionsReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/commissionsreport";

    static reportsTourMegaReport = (aid?: string, tid?: string) => TTPaths.tourHome(aid, tid) + "/reports";


    static itineraryToolHome = (aid?: string, tid?: string, bid?: string) => TTPaths.tourHome(aid, tid) + "/itinerary/" + (bid || ":budgetId");
    static itineraryToolShowPopup = (tab?: BudgetShowTabType, aid?: string, tid?: string, bid?: string, sid?: string) => TTPaths.itineraryToolHome(aid, tid, bid) + "/" + (sid || ":showId") + "/" + (tab || "");


    static budgetingHome = (aid?: string, tid?: string, bid?: string) => TTPaths.tourHome(aid, tid) + "/budget/" + (bid || ":budgetId");
    static budgetingScreen = (categoryName: string, aid?: string, tid?: string, bid?: string) => {
        if (categoryName == "Merch Costs") categoryName = "Merch"; // merch screen has other stuff
        return TTPaths.budgetingHome(aid, tid, bid) + "/" + categoryName.toLowerCase().replace(/ /g, '');
    }
    static budgetingSubCategoryAllocationsPopup = (categoryName: string, aid?: string, tid?: string, bid?: string, scid?: string) => TTPaths.budgetingScreen(categoryName, aid, tid, bid) + "/allocations/" + (scid || ":subcategoryId");
    static budgetItineraryScreenName = "Itinerary";
    static budgetingItinerary = (aid?: string, tid?: string, bid?: string) => TTPaths.budgetingScreen(TTPaths.budgetItineraryScreenName, aid, tid, bid);
    static budgetingShowPopup = (tab?: BudgetShowTabType, aid?: string, tid?: string, bid?: string, sid?: string) => TTPaths.budgetingItinerary(aid, tid, bid) + "/" + (sid || ":showId") + "/" + (tab || "");

    static superAdmin = () => "/superadmin";

}

export class TourMegaReportPaths {

    constructor(private getBase: (aid?: string, tid?: string) => string) { }

    default = (aid?: string, tid?: string) => this.getBase(aid, tid);
    tourIncome = (aid?: string, tid?: string) => this.getBase(aid, tid) + "/income";
    show = (aid?: string, tid?: string, showId?: string) => this.getBase(aid, tid) + "/show/" + (showId || ":showId");
    costsAsPercentage = (aid?: string, tid?: string) => this.getBase(aid, tid) + "/costs";
    costCategory = (aid?: string, tid?: string, category?: string) => this.getBase(aid, tid) + "/costcategory/" + (category || ":category");
    tourProfit = (aid?: string, tid?: string) => this.getBase(aid, tid) + "/profit/";
    merchIncome = (aid?: string, tid?: string) => this.getBase(aid, tid) + "/merch/";
    transactions = (aid?: string, tid?: string, category?: string, subcategory?: string) => this.getBase(aid, tid) + "/transactions/" + (category || ":category") + "/" + (encodeForUrl(subcategory) || ":subcategory");
}




// export const reportsTourMegaReportPaths = (getBase: (aid?: string, tid?: string) => string) => {

//     return {
//         reportsTourMegaReportTourIncome: (aid?: string, tid?: string) => getBase(aid, tid) + "/income",
//         reportsTourMegaReportShow: (aid?: string, tid?: string, showId?: string) => getBase(aid, tid) + "/show/" + (showId || ":showId"),
//         reportsTourMegaReportCostsAsPercentage: (aid?: string, tid?: string) => getBase(aid, tid) + "/costs",
//         reportsTourMegaReportCostCategory: (aid?: string, tid?: string, category?: string) => getBase(aid, tid) + "/costcategory/" + (category || ":category"),
//         reportsTourMegaReportTourProfit: (aid?: string, tid?: string) => getBase(aid, tid) + "/profit/",
//         reportsTourMegaReportMerchIncome: (aid?: string, tid?: string) => getBase(aid, tid) + "/merch/",
//         reportsTourMegaTransactions: (aid?: string, tid?: string, category?: string, subcategory?: string) => getBase(aid, tid) + "/transactions/" + (category || ":category") + "/" + (encodeForUrl(subcategory) || ":subcategory"),
//     }

// }


export type BudgetShowTabType = "income" | "costs" | "otherincome" | "merch";
