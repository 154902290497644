import * as React from "react";
import { CSSProperties, DOMAttributes } from 'react';
import { Button } from 'react-bootstrap/lib';
import { flexHorizontal } from '../handyStyles';
import "./buttons.less";
import { WaitLoader } from './Loader';

export interface ButtonProps {
    onClick?: (event?) => void;
    label: React.ReactNode;
    disabled?: boolean;
    className?: string;
    type?: string;
    title?: string;
    style?: CSSProperties;
}


export const NavigationButton = (props: ButtonProps) =>
    <Button
        disabled={props.disabled}
        className={props.className}
        onClick={props.onClick}>
        {props.label}
    </Button>


export const GreenActionButton = (props: ButtonProps & { waiting?: boolean } & DOMAttributes<any>) =>
    <ActionButton
        {...props}
        className={props.className + " btn-green"}
    />

// export const TileActionButton = (props: ButtonProps & { waiting?: boolean } & DOMAttributes<any>) =>
// <GreenActionButton
//     {...props}
//     className={props.className + " btn-green btn-tile"}
// />

export const PurpleActionButton = (props: ButtonProps & { waiting?: boolean } & DOMAttributes<any>) =>
    <ActionButton
        {...props}
        className={props.className + " btn-purple"}
    />

export const ActionButton = (props: ButtonProps & { waiting?: boolean, secondary?: boolean, transparent?: boolean, mini?: boolean } & DOMAttributes<any>) =>
    <Button
        //  bsStyle={props.secondary ? "secondary" : "primary"}
        bsStyle={props.secondary ? undefined : "primary"}
        className={`button-action ${props.className || ""} ${props.mini ? "mini" : ""} ${props.secondary ? "btn-secondary" : ""} ${props.transparent ? "btn-transparent" : ""}`}
        color="primary"
        type={props.type}
        title={props.title}
        disabled={props.disabled || props.waiting}
        style={props.style}
        onClick={props.onClick} >
        {props.waiting &&
            <span style={{ position: "absolute", marginLeft: 6 }}><WaitLoader size={20} /></span>
        }
        <span style={props.waiting ? { visibility: "collapse" } : null}>{props.label}</span>
    </Button>

// handy for use in a row that is horizontal and all the bootstrap contols have labels
// this adds a label sized padding to the top of the button
export const HorizontalButton = (props: ButtonProps & DOMAttributes<any>) =>
    <Button
        bsStyle="primary"
        type={props.type}
        className={`button-action ${props.className}`}
        color="primary"
        style={{ height: 43, marginTop: 22 }}
        disabled={props.disabled}
        onClick={props.onClick} >
        {props.label}
    </Button>

export const DangerButton = (props: ButtonProps) =>
    <Button
        bsStyle="danger"
        className={`button-action ${props.className || ""}`}
        color="primary"
        disabled={props.disabled}
        onClick={props.onClick} >
        {props.label}
    </Button>

export interface LinkButtonProps {
    children: any,
    onClick?: (event?) => void,
    style?: CSSProperties,
    className?: string,
    disabled?: boolean
}

export const SubtleDangerButton = (props: LinkButtonProps) =>
    <LinkButton
        className={`danger-button ${props.className}`}
        disabled={props.disabled}
        onClick={props.onClick} >
        {props.children}
    </LinkButton>

export const SubtleDangerLink = (props: LinkButtonProps) =>
    <LinkNormal
        className={`danger-button ${props.className}`}
        style={{ textDecoration: "none" }}
        onClick={props.onClick} >
        {props.children}
    </LinkNormal>

export const LinkButton = (props: LinkButtonProps) =>
    <Button
        bsStyle="link"
        className={props.className}
        disabled={props.disabled}
        onClick={props.onClick} >
        {props.children}
    </Button>

export const CloseSave = (props: {
    onClose?: (event?) => void,
    onSave?: (event?) => void,
    saveDisabled?: boolean,
    closeDisabled?: boolean,
    hideClose?: boolean,
    style?: CSSProperties,
    saveClassName?: string,
    saveText?: string,
    closeText?: string,
}) =>
    <div style={{ textAlign: "right", ...props.style }}>
        {!props.hideClose &&
            <>
                <LinkButton
                    disabled={props.closeDisabled}
                    onClick={props.onClose} >{props.closeText || "Close"}</LinkButton>
                <div className="vertical-spacer"></div>
            </>
        }
        <ActionButton
            className={props.saveClassName}
            label={props.saveText || "Save"}
            disabled={props.saveDisabled}
            onClick={props.onSave} />
    </div>

export const CloseSubmit = (props: {
    onClose?: (event?) => void,
    okDisabled?: boolean,
    style?: CSSProperties,
    saveText?: string,
    closeText?: string,
}) =>
    <div style={{ textAlign: "right", ...props.style }}>
        <LinkButton
            onClick={props.onClose} >{props.closeText || "Close"}</LinkButton>
        <div className="vertical-spacer"></div>
        <GreenActionButton
            label={props.saveText || "Save"}
            disabled={props.okDisabled}
            type="submit"
        />
    </div>


// note.. this isn't a functional component because
// the date range picker doesn't like using them
export class LinkNormal extends React.Component<{
    onClick?: (event) => void,
    title?: string,
    children,
    disabled?: boolean,
    className?: string,
    style?: CSSProperties,
    notLink?: boolean;
}, {}> {

    render() {
        const { props } = this;

        if (props.notLink) return props.children;

        return <a
            style={{ cursor: props.disabled ? "not-allowed" : "pointer", ...props.style }}
            className={props.className + (props.disabled ? " disabled" : "")}
            onClick={e => { if (!props.disabled && props.onClick) props.onClick(e) }}
            title={props.title}
        >
            {props.children}
        </a>

    }
}


export interface ToggleButtonListItem {
    text: string;
    key: string;
    selected: boolean;
}

export interface ToggleButtonsProps {
    items: ToggleButtonListItem[];
    onChange: (key: string, selected: boolean) => void;
}

export const ToggleButtons = (props: ToggleButtonsProps) =>
    <div style={flexHorizontal} >
        {props.items.map(item =>
            <ActionButton
                key={item.key}
                label={item.text}
                className={item.selected ? "active" : ""}
                onClick={() => props.onChange(item.key, !item.selected)}
            >
                {item.text}
            </ActionButton>
        )}
    </div>

export const CloseButton = (props: { onClick?: () => void }) =>
    <span onClick={props.onClick} style={{ padding: 2, cursor: "pointer", fontWeight: 600, color: "#888" }} >&times;</span>

// export const BigRedCloseButton = (props: { onClick: () => void }) =>
//     <span onClick={props.onClick} style={{ padding: 2, cursor: "pointer", fontWeight: 600, fontSize: "1.4em" }} className="important-text">&times;</span>
