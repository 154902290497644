import * as React from "react";
import { CSSProperties } from 'react';
import { LinkButton } from "./Buttons";
import "./cards.less";
import { LinkButtonNavigation } from './Navigation';

export interface CardLink {
    text: string;
    to?: string;
    onClick?: () => void;
}


export const CardLayout = (props: { children, style?: CSSProperties }) =>
    <div
        style={{
            ...props.style,
            width: "100%",
            display: "flex", /* or inline-flex */
            flexFlow: "row wrap",
        }}>
        {props.children}
    </div>


// const listSpacing = { margin: 10 }
const listCardSize = {
    flexBasis: "auto",
    flexGrow: 1,
    flexShrink: 1,
    // minWidth: 150,
    // maxWidth: 350
}


// a care that has a bunch of navigation at the bottom
export const InfoNavCard = (props: { className?: string, actions?: CardLink[], children }) =>
    <Card
        className={props.className}
        style={{
            ...listCardSize,
            // ...listSpacing,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
        <CardBody>
            {props.children}
        </CardBody>
        <CardFooter style={{ whiteSpace: "nowrap" }}>
            {!!props.actions && props.actions.map((a, i) => {
                return !!a.to
                    ? <LinkButtonNavigation key={a.to} to={a.to} label={a.text} />
                    : <LinkButton key={i} onClick={a.onClick}>{a.text}</LinkButton>
            }
            )}
        </CardFooter>
    </Card>

export const GenericCard = (props: {
    children,
    footerChildren?,
    className?: string,
    bodyClassName?: string,
    fullWidth?: boolean,
    style?: CSSProperties
}) =>
    // <Card style={{ ...listSpacing }} className={props.className}>
    <Card className={`${props.className} ${props.fullWidth ? "full-width" : ""}`} >
        <CardBody style={props.style} className={props.bodyClassName}>
            {props.children}
        </CardBody>
        {!!props.footerChildren && <CardFooter>{props.footerChildren}</CardFooter>}
    </Card>

export const InvisibleCard = (props: { children, className?: string, fullWidth?: boolean }) =>
    <Card className={`card-invisible ${props.className} ${props.fullWidth ? "full-width" : ""}`}>
        <CardBody>
            {props.children}
        </CardBody>
    </Card>


export const HeaderCard = (props: { children, className?: string, heading: string }) =>
    // <Card style={{ ...listSpacing }} className={props.className}>
    <Card className={props.className}>
        <CardHeader>{props.heading}</CardHeader>
        <CardBody>
            {props.children}
        </CardBody>
    </Card>

// // a card that will be by itself doing some action.. eg a form or report
// // it's size is determined by it's contents
// export const LonelyCard = (props: { children: ReactElement<any>, style?: CSSProperties }) =>
//     <Card style={{ ...listSpacing }}>
//         <CardBody>
//             {props.children}
//         </CardBody>
//     </Card>


// // a card containing one navigation element that should naviigate to another  screen... 
// export const NavCard = (props: { to: H.LocationDescriptor, children: ReactElement<any> }) =>
//     <Card style={{ ...listCardSize, ...listSpacing }}>
//         <LinkNavigation to={props.to}  >
//             <CardLayout >
//                 {props.children}
//             </CardLayout>
//         </LinkNavigation>
//     </Card>

// // a card containing one navigation element that should go to an action screen... has a dotted border
// export const ActionNavCard = (props: { to: H.LocationDescriptor, children: ReactElement<any> }) =>
//     <Card style={{ ...listCardSize, ...listSpacing, boxShadow: "none", border: "2px dashed #aaa", background: "none" }}>
//         <LinkNavigation to={props.to}  >
//             <CardLayout  >
//                 {props.children}
//             </CardLayout>
//         </LinkNavigation>
//     </Card>

export const Card = (props: { style?: CSSProperties, children, className?: string }) =>
    <div className={`card panel panel-default ${props.className}`} style={props.style}>{props.children}</div>

export const CardHeader = (props: { style?: CSSProperties, children }) =>
    <div className="panel-heading" style={props.style}>{props.children}</div>

export const CardBody = (props: { style?: CSSProperties, children, className?: string }) =>
    <div className={`panel-body ${props.className}`} style={props.style}>{props.children}</div>

export const CardFooter = (props: { style?: CSSProperties, children }) =>
    <div className="panel-footer" style={props.style}>{props.children}</div>
