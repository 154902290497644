import { toast } from 'react-toastify';



export class SystemMessage {
    static showSaved() {
        toast.success("Saved", {
            autoClose: 1000
        });
    }

    static showSuccess(m) {
        toast.success(m);
    }

    static showError(m) {
        console.error("ERROR: " + m);
        toast.error(m);
    }

    static showInfo(m) {
        console.log("INFO: " + m);
        toast.info(m, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    static showWarning(m) {
        console.log("WARN: " + m);
        toast.warn(m);
    }

}