import * as React from 'react';
import { useEffect } from 'react';
import { CloseSave, GreenActionButton, LinkButton, SubtleDangerLink } from './Buttons';
import { GenericCard } from './Cards';
import "./modals.less";
import { PopupBox, PopupCloseButton } from './PopupBox';

export interface ConfirmBoxProps {
    title?: string;
    children;
    okText?: string;
    cancelText?: string;
    okClassName?: string;
    onCancel: () => void,
    onOk: () => void,
}

export const ConfirmBox = (props: ConfirmBoxProps) => {

    // set focus to the ok button
    useEffect(() => {
        const cb = document.getElementsByClassName("confirm-box");
        if (!cb || cb.length == 0) return;
        const pb: any = cb[0].getElementsByClassName("popup-save-btn");
        if (!pb || pb.length == 0) return;

        if (!!pb[0].focus) pb[0].focus()
    }, [])

    return <div className="confirm-box">
        <PopupBox onClose={props.onCancel}>
            <GenericCard>
                <PopupCloseButton onClose={props.onCancel} />
                {!!props.title &&
                    <h2>{props.title}</h2>
                }
                <div className="horizontal-spacer"></div>
                {props.children}
                <div className="horizontal-spacer"></div>
                <div className="horizontal-spacer"></div>
                <CloseSave
                    onSave={props.onOk}
                    onClose={props.onCancel}
                    saveClassName={`${props.okClassName || ""} popup-save-btn`}
                    saveText={props.okText || "OK"}
                    closeText={props.cancelText || "Cancel"}
                />
            </GenericCard>
        </PopupBox>
    </div>
}

export const ConfirmLeaveOrSaveBox = (props: {
    onCancel: () => void,
    onSave: () => void,
    onExit: () => void,
}) =>
    <div className="confirm-box">
        <PopupBox onClose={props.onCancel}>
            <GenericCard>
                <PopupCloseButton onClose={props.onCancel} />
                <div className="horizontal-spacer"></div>
                <div className="horizontal-spacer"></div>
                <h1>You have unsaved changes</h1>
                <p>Do you want to save your changes?</p>
                <div className="horizontal-spacer"></div>
                <div className="horizontal-spacer"></div>
                <div style={{ textAlign: "right" }}>
                    <GreenActionButton label="Save &amp; Exit" onClick={props.onSave} />
                    <div className="vertical-spacer"></div>
                    <SubtleDangerLink onClick={props.onExit}>Don't Save</SubtleDangerLink>
                    <div className="vertical-spacer"></div>
                    <LinkButton onClick={props.onCancel} >Cancel</LinkButton>
                </div>
                <div className="vertical-spacer"></div>
            </GenericCard>
        </PopupBox>
    </div>
