export const FeatureFlags = {
    // helperInfo: true,
    // oldHelperInfo: false,
    oldHelperInfoAlwaysOn: false,

    hasPersonnelContingency: false,

    hasArtistSubcategoryTool: true,

    // useCarDriving: true,

    qboApiTransactionIntegrations: false,

    useSeperateTravelCalcs: false,
}