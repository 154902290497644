import * as React from "react";
import "./popupBox.less";
import { CloseButton } from "./Buttons";
import { NavLink } from "react-router-dom";


export class PopupBox extends React.Component<{
    children,
    modalHolderClassName?: string,
    onClose?: () => void
}, {}> {

    backgroundRef: any = "bla";
    // wrapperRef: any = "bla";

    constructor(props) {
        super(props);

        // so that "this" is actually this
        this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
        this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        document.addEventListener('keydown', this.handleDocumentKeyDown);

    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    handleClose = () => {
        if (!!this.props.onClose) {
            this.props.onClose();
        }
    }

    handleDocumentMouseDown = (e) => {
        // only close if the click is on the outside modal
        // if (this.wrapperRef == e.target || this.backgroundRef == e.target) {
        if (this.backgroundRef == e.target) {
            this.handleClose();
        }
    }

    handleDocumentKeyDown = (e) => {
        // escape key
        if (e.keyCode == 27) {
            this.handleClose();
        }
    }

    render() {

        return <div id="modal-holder" className={this.props.modalHolderClassName || ""} ref={n => this.backgroundRef = n} >
            {/* <div id="modal-inside-wrapper" ref={n => this.wrapperRef = n}> */}
            {this.props.children}
            {/* </div> */}
        </div>
    }
}

export const PopupBoxHeader = (props: { children }) =>
    <div id="modal-header">
        <div className="modal-header-inside">
            {props.children}
        </div>
    </div>

export const PopupBoxBody = (props: { children }) =>
    <div id="modal-body">
        <div className="modal-body-inside">
            {props.children}
        </div>
    </div>

export const PopupBoxFooter = (props: { children }) =>
    <div id="modal-footer">
        <div className="modal-footer-inside">
            {props.children}
        </div>
    </div>

export const PopupCloseButton = (props: { onClose: () => void }) =>
    <div style={{ textAlign: "right" }}>
        <CloseButton onClick={props.onClose} />
    </div>

export const PopupCloseNavigation = (props: { to: string }) =>
    <div style={{ textAlign: "right" }}>
        <NavLink to={props.to}>
            <CloseButton />
        </NavLink>
    </div>

