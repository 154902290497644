import * as React from 'react';
import { useEffect, useState } from 'react';
import Chart from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink, Route, RouteComponentProps, withRouter, useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthService from '../auth/authService';
import { CurrentUserState } from '../auth/currentUserState';
import { signInOrSignUp, tempSaveCurrentBrowserPath } from '../auth/urlRedirect';
import { WaitLoaderFullScreen } from '../common/components/Loader';
import { PopupMenu } from '../common/components/PopupMenu';
import { useInterval } from "../common/handyHooks";
import { CurrentArtist } from './artist/currentArtist';
import { MainHeading } from "./components/MainHeading";
import { FeedbackForm } from './feedback/FeedbackForm';
import { Header } from './Header';
import { BreadCrumbs } from './navigation/BreadCrumbs';
import { MainNavigation } from "./navigation/MainNavigation";
import { TourTracksAction } from './state/actions';
import { LoadingStatus, TourTracksState } from './state/state';
import "./tourTracksMainScreen.less";
import { TourTracksApp } from "./state/hooks"

// At runtime, Redux will merge together...
export type TourTracksProps = {
    tourTracks: TourTracksApp,
    artistId: string,
    tourId: string,
    pathName: string,
}

enum RenewLoginResultEnum {
    WASOK, RENEWED
}

const TourTracksMainScreen = (props: TourTracksProps) => {

    const { state, actions } = props.tourTracks;

    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const [refreshHack, setRefreshHack] = useState(new Date());



    // update auth token every hour (it lasts two hours by default)
    useInterval(() => {
        const auth = new AuthService();
        auth.renew();
    }, 1000 * 60 * 60)


    useEffect(() => {
        console.log("------------");

        // this code will force any chart js charts to resize
        // since once you press print there is no javascript to resize the chart
        // this way we catch the print button and force a resize
        function beforePrint() {
            // alert("dfgsdfg");
            for (const id in (Chart as any).instances) {
                (Chart as any).instances[id].resize()
            }
        }
        if (window.matchMedia) {
            let mediaQueryList = window.matchMedia('print')
            mediaQueryList.addListener((mql) => {
                if (mql.matches) {
                    beforePrint()
                }
            })
        }
        window.onbeforeprint = beforePrint

        // when we get focus, check that the user is logged in
        // for example, they might have come back from a screensaver and 
        // we need to know whther to refresh their login or now
        // window.onfocus = this.forceTryLogin;

        console.log("------------");
    }, []);


    const startStuff = async (artistId: string, tourId: string) => {

        // // no change? get out of here
        // const sameArtist = !!state.artist && state.artist.id == artistId;
        // const sameTour = !!state.tour && state.tour.id == tourId;
        // if (sameArtist && sameTour) return;

        // console.log("!!!!!!!!@@@@@@@@@@@******************!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

        // hack.. artist id's should always be guids and have "-" in them
        // this is because there's a dumb thing where we might think the artist id is the first route like /newartist
        // mini-hack: if the artist id isn't a guid.. assume there isn't one
        if (!!artistId && !artistId.includes("-")) artistId = "";

        await actions.selectArtist(artistId, false);
        // debugger;
        await actions.selectTour(tourId, false);

        // keep a record of the current artist (if any)
        // in local storage so we can send it as a header.. for authentication purposes etc
        CurrentArtist.set(artistId);

        // const user = new CurrentUserState();

        ensureLogin().then(r => {
            if (state.loadingAppData == LoadingStatus.NotLoaded) {
                actions.loadAppData();
            }
        })

    }

    // when the artist ids change, make sure we have the right artist/tour data
    useEffect(() => {
        startStuff(props.artistId, props.tourId);
    }, [props.artistId, props.tourId]);



    const forceTryLogin = () => {
        console.log("focus");
        ensureLogin().then(r => {
            if (r == RenewLoginResultEnum.RENEWED) {
                // only force an update if we have done a renew
                // otherwise we will have trouble with things that are meant to leave focus, 
                // eg: file uploaders etc
                console.log("force");
                setRefreshHack(new Date())
            }
        })
    }

    const doLogin = () => {
        // debugger;
        tempSaveCurrentBrowserPath();
        // defaulting to signup for non-signed-in users
        // if they have an account but are logged out, auth0
        // should remember this anyway (i hope/think)
        const auth = new AuthService();
        auth.login(signInOrSignUp());
    }


    const ensureLogin = (): Promise<RenewLoginResultEnum> => {
        const user = new CurrentUserState();
        const auth = new AuthService();

        return new Promise<RenewLoginResultEnum>((resolve, reject) => {

            if (user.loginExpired()) {
                console.log("LOGIN EXPIRED");
                auth.renew().then(() => {
                    if (!user.isAuthenticated()) {
                        console.log("RENEW FAILED");
                        doLogin();
                    }
                    else {
                        console.log("RENEW SUCESS");
                        resolve(RenewLoginResultEnum.RENEWED);
                    }
                }).catch(r => {
                    console.log("RENEW ERROR");
                    doLogin();
                });
            }
            else if (!user.isAuthenticated()) {
                console.log("RENEW ERROR");
                doLogin();
            }
            else {
                // all clear continue on
                console.log("LOGIN OK");
                resolve(RenewLoginResultEnum.WASOK);
            }
        });
    }




    const isLoading =
        (state.loadingAppData != LoadingStatus.Loaded)
        && (state.loadingArtist != LoadingStatus.Loaded)
        && (state.loadingTour != LoadingStatus.Loaded)
        ;

    // return <WaitLoaderFullScreen message="Checking login..." />;

    const user = new CurrentUserState();
    if (!user.isAuthenticated()) {
        forceTryLogin();
        return <WaitLoaderFullScreen message="Checking login..." />;
    }

    document.title = "TourTracks";
    if (!!state.artist) document.title += " - " + state.artist.name;
    if (!!state.tour) document.title += " - " + state.tour.name;

    return <div>
        <ToastContainer />
        <header>
            <Header
                artist={state.artist}
                tour={state.tour}
                onOpenFeedback={() => setFeedbackOpen(!feedbackOpen)}
            />
        </header>
        <div className="body-stuff">
            <div className="hide-small-screen"><BreadCrumbs  {...props} /></div>
            <MainHeading  {...props} />
            <MainNavigation {...props} />

            {feedbackOpen &&
                // note we are keeping this out of the header for annoyon opacity z-index reasons
                //  see https://stackoverflow.com/questions/2837057/what-has-bigger-priority-opacity-or-z-index-in-browsers
                <PopupMenu rounded className="feedback-popup">
                    <FeedbackForm onClose={() => setFeedbackOpen(false)} />
                </PopupMenu>

            }

            {isLoading && <WaitLoaderFullScreen />}
        </div>
    </div>
}

// // export default connect(
// export const Bla = connect<TourTracksState, any, any>(
//     (state: ApplicationState) => state.tourTracks, // Selects which state properties are merged into the component's props
//     actionCreators                               // Selects which action creators are merged into the component's props
// )(TourTracksMainScreen);


const TourTracksMainScreenRouted = (props: {
    tourTracks: TourTracksApp,
}) => {

    const match = useRouteMatch<{ artistId: string, tourId: string }>();

    return <TourTracksMainScreen
        tourTracks={props.tourTracks}
        artistId={match.params.artistId}
        tourId={match.params.tourId}
        pathName={match.path}
    />

}


export default TourTracksMainScreenRouted;