import * as React from 'react';
import { CurrentUserState } from '../auth/currentUserState';
import { LinkNormal } from '../common/components/Buttons';
import { TourTracksProps } from './TourTracksMainScreen';
import { Opener } from '../common/components/Opener';
import Auth from '../auth/authService';
import "./header.less";
import { LinkNavigation } from '../common/components/Navigation';
import { PopupMenu } from '../common/components/PopupMenu';
import { TTPaths } from './navigation/paths';
import { TTApi } from '../api/ttApi';
import { resetHelperInfo } from './helpTips/HelpTutorial';
import { FeatureFlags } from './helpers/featureFlags';
import { ArtistModel, TourModel } from '../api/services';

export const TTLogo = () => <div className="logo-container">
    <LinkNavigation scrollToTop={true} to={`/`} >
        <img className="no-print" src="https://tourtracks.blob.core.windows.net/assets/TT_LogoFull%20MC1710B.svg" alt="TourTracks" />
    </LinkNavigation>
    {/* <a href="/">
        <img className="no-print" src="https://tourtracks.blob.core.windows.net/assets/TT_LogoFull%20MC1710B.svg" alt="TourTracks" />
    </a> */}
</div>;

const Avatar = (props: { user: CurrentUserState }) => {
    const { user } = props;

    const pic = user.picture || "https://tourtracks.blob.core.windows.net/assets/placeholder-photo.jpg";

    return !user.name
        ? null
        : <img
            className="avatar no-print"
            src={pic}
            alt={user.name}
        />
}


// export const Header = (props: TourTracksProps) => {
export const Header = (props: { artist: ArtistModel, tour: TourModel, onOpenFeedback?: () => void }) => {


    // NOTE: if you do a lot to this... remember there is a print version too

    var auth = new Auth();

    const user = new CurrentUserState();
    const { artist, tour, onOpenFeedback } = props;

    return <div className='top-nav no-print'>
        <div className="top-nav-left">
            <TTLogo />
        </div>
        <div className="top-nav-middle hide-small-screen">
            {!!artist &&
                <LinkNavigation to={TTPaths.artistHome(artist.id)} hideLinkStyle>
                    <h1>{artist.name}</h1>
                </LinkNavigation>
            }
            {!!tour &&
                <LinkNavigation to={TTPaths.tourHome(artist.id, tour.id)} hideLinkStyle>
                    <h2>{tour.name}</h2>
                </LinkNavigation>
            }
        </div>
        <div className="top-nav-right no-print">
            {!!onOpenFeedback &&
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onOpenFeedback()}
                    className="subtle-text hide-small-screen feedback-link"
                ><strong>Send Feedback</strong></div>
            }

            <Opener defaultComponent={<Avatar user={user} />}>
                <PopupMenu>
                    <a href="https://tourtracks.co/contact/" target="_blank" >Contact Us</a>
                    {/* {!!artist && FeatureFlags.oldHelperInfo &&
                        <>
                            <LinkNormal onClick={async () => {
                                await resetHelperInfo(artist.id);
                                window.location.reload();
                            }}>Restart Tutorial</LinkNormal>
                            <div className="popup-line"></div>
                        </>
                    } */}
                    {!!auth.currentUser && auth.currentUser.isAuthenticated() &&
                        <>
                            <div className="popup-line"></div>
                            <LinkNormal onClick={() => auth.logout()}>Sign Out</LinkNormal>
                        </>
                    }
                </PopupMenu>
            </Opener>

        </div>
    </div>

}

